import {useCallback, useEffect} from 'react'
import {withTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import Stepper from '../../components/Stepper/Stepper'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {projectApi} from '../../services'
import {getInvestmentStorage} from '../../store/localStorage/investment'
import {useCustomerSlice} from '../../store/slices/customer'
import {useProjectSlice} from '../../store/slices/project'
import {useStepperSlice} from '../../store/slices/stepper'
import Config from '../../utils/config'

import styles from './Investment.module.scss'

const Investment = () => {
  const navigate = useNavigate()
  const {itemId} = useParams()
  const {steps, activeStep, nextStep, setActiveStep, setActiveItemId, initStepper} =
    useStepperSlice()
  const {
    customer_info: {id: customerInfoId, identity_verification_status},
    refreshCustomer,
  } = useCustomerSlice()

  const {setProject, id: projectId} = useProjectSlice()

  const getProject = useCallback(
    async (id: number) => {
      try {
        const project = await projectApi.getProject(id)
        setProject(id, project)
      } catch ({response: {status}}: any) {
        if (status === 404) navigate(PATHS.HOME)
      }
    },
    [itemId, projectId, setProject],
  )

  useEffect(() => {
    initStepper('investment')
    setActiveItemId(+(itemId || ''))
  }, [itemId])

  useEffect(() => {
    if (!identity_verification_status || !Config.isKycEnabled) return
    if (!!getInvestmentStorage()?.activeStepNumber) {
      if (
        identity_verification_status !== 'approved' &&
        getInvestmentStorage().activeStepNumber !== steps[0].number
      ) {
        setActiveStep(steps[0])
        return
      }
    }
    if (identity_verification_status === 'approved' && activeStep.number === steps[0].number)
      setActiveStep(steps[1])
  }, [identity_verification_status])

  useEffect(() => {
    if (!projectId) !!itemId && getProject(+itemId)
  }, [itemId, projectId])

  useEffect(() => {
    if (!customerInfoId) refreshCustomer()
  }, [customerInfoId])

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <Stepper steps={steps} activeStep={activeStep} handleNextStep={nextStep} />
      </BannerContainer>
    </BannerSection>
  )
}
export default withTranslation()(Investment)
