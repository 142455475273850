import {useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import {CURRENCY} from '../../../../../constants/currencies'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'
import {formatStringToNumber} from '../../../../../utils/formatNumber'
import {FundsWithdrawalForm} from '../Usdt'

import styles from './styles.module.scss'

const WithdrawalConfirmation = ({t}: WithTranslation) => {
  const {lastStep} = useWithdrawalSlice()
  const {getValues} = useFormContext<FundsWithdrawalForm>()

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={() => lastStep()} />
        <span>{t('withdrawal.confirmTransaction')}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.details}>
          <div className={styles.line} />
          <div className={styles.subtitle}>{t('withdrawal.detail')}</div>
          <div className={styles.row}>
            <span>{t('withdrawal.amount')}</span>
            <span>
              {formatStringToNumber(getValues('fundAmount'))} {CURRENCY.USDT}
            </span>
          </div>
          <div className={styles.row}>
            <span>{t('withdrawal.address')}</span>
            <span>
              {`${getValues('destinationAddress').substring(0, 6)}...${getValues(
                'destinationAddress',
              ).substring(
                getValues('destinationAddress').length - 6,
                getValues('destinationAddress').length,
              )}`}
            </span>
          </div>
          <div className={styles.row}>
            <span>{t('withdrawal.estimatedTransactionFee')}</span>
            <span>
              {0} {CURRENCY.USDT}
            </span>
          </div>
          <div className={styles.line} />
          <div className={styles.row}>
            <span className={styles.bold}>{t('withdrawal.willReceive')}</span>
            <span className={styles.bold}>
              {formatStringToNumber(getValues('fundAmount'))} {CURRENCY.USDT}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(WithdrawalConfirmation)
