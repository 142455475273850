import {useCallback, useEffect, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../components/Button/Button'
import Dropdown, {IValue} from '../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../components/TextInput/TextInput'
import * as contactForm from '../../../../constants/personalInformationForm'
import {IContactForm} from '../../../../constants/personalInformationForm'
import {REG_EX} from '../../../../constants/regExpression'
import useMemoCountryCodes from '../../../../hooks/useMemoCountryCodes'
import {magic} from '../../../../libs/magic'
import {customerApi} from '../../../../services'
import {Country} from '../../../../services/interfaces/IAddress'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePersonFormsSlice} from '../../../../store/slices/personForms'
import {getAreaCode, getPhoneNumber} from '../../../../utils/parsePhoneNumber'
import AddressForm from '../../commonForms/AddressForm'

import styles from '../ProviderInfo.module.scss'

const LegalRepresentativeContactForm = ({t}: WithTranslation) => {
  const {lastForm, nextForm} = usePersonFormsSlice()
  const {
    customer_info: {id, email, phone_number, address},
    setCustomerData,
  } = useCustomerSlice()
  const methods = useForm<IContactForm>({
    defaultValues: {
      mail: email || '',
      areaCode: getAreaCode(phone_number),
      mobile: getPhoneNumber(phone_number),
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country?.toLocaleUpperCase() as Country,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const countryCodes = useMemoCountryCodes(t)

  const onSubmit = async (data: IContactForm) => {
    try {
      setSubmitting(true)
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        email: data.mail,
        phone_number: data.areaCode + data.mobile.toString(),
        address: {
          street: data.street,
          number: data.number.toString(),
          floor: data.floor,
          department: data.department,
          cp: data.cp,
          city: data.city,
          province: data.province,
          country: data.country,
        },
      })
      setCustomerData({customer_info: responseData})
      nextForm()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IContactForm> | FieldErrors<IContactForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  const gettingEmail = useCallback(async () => {
    const {email} = await magic.user.getInfo()
    if (!!email) setCustomerData({customer_info: {email: email}})
  }, [magic])

  useEffect(() => {
    if (!!magic) gettingEmail()
  }, [magic])

  useEffect(() => {
    methods.reset({
      mail: email || '',
      areaCode: getAreaCode(phone_number),
      mobile: getPhoneNumber(phone_number),
      street: address?.street || '',
      number: address?.number || '',
      floor: address?.floor || '',
      department: address?.department || '',
      cp: address?.cp || '',
      city: address?.city || '',
      province: address?.province || '',
      country: address?.country?.toLocaleUpperCase() as Country,
    })
  }, [email, phone_number, address, methods])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {<Arrow className={styles.prev} onClick={() => lastForm()} />}
        <span className={styles.title}>
          {t('personalInformation.provider.legalRepresentativeContactForm.title')}
        </span>
        <span className={styles.clarification}>{t('personalInformation.clarification')}</span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <TextInput
            label={`${t(`user.${contactForm.MAIL}`)}`}
            name={contactForm.MAIL}
            pattern={REG_EX.EMAIL}
            withUseForm
            underlined
            required
          />
          <div className={classNames(styles.row, styles.specialReverse)}>
            <Controller
              control={methods.control}
              name={contactForm.AREA_CODE}
              rules={{
                required: true,
              }}
              render={({field: {value, onChange}}) => (
                <Dropdown
                  label={`${t(`user.${contactForm.AREA_CODE}`)}`}
                  name={contactForm.AREA_CODE}
                  className={classNames(styles.dropdown, styles.areaCode)}
                  customClasses={{
                    head: styles.head,
                    arrow: styles.arrow,
                  }}
                  value={countryCodes.find(countryCode => value === countryCode.key) as IValue}
                  setValue={onChange}
                  options={countryCodes}
                  underlined
                />
              )}
            />
            <TextInput
              label={`${t(`user.${contactForm.MOBILE}`)}`}
              name={contactForm.MOBILE}
              placeholder={`${t(`user.${contactForm.MOBILE}Placeholder`)}`}
              withUseForm
              underlined
              required
            />
          </div>
          <AddressForm styles={styles} />

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(LegalRepresentativeContactForm)
