import {ReactNode} from 'react'

import AddBankAccount from './AddBankAccount'
import CheckBankAccount from './CheckBankAccount'
import ChooseAccount from './ChooseAccount'
import WithdrawalAmount from './WithdrawalAmount'

export const withdrawalPesosSteps: ReactNode[] = [
  <AddBankAccount key={1} />,
  <ChooseAccount key={2} />,
  <CheckBankAccount key={3} />,
  <WithdrawalAmount key={4} />,
]

export enum WITHDRAWAL_PESOS_STEPS {
  ADD_ACCOUNT,
  CHOOSE_ACCOUNT,
  CHECK_ACCOUNT,
  WITHDRAWAL_AMOUNT,
}
