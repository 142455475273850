import {ReactComponent as Success} from '../../../../../assets/icons/circle-checkmark.svg'
import {ReactComponent as User} from '../../../../../assets/icons/user.svg'
import {ReactComponent as Wallet} from '../../../../../assets/icons/wallet.svg'
import {IStep} from '../../../../../components/Stepper/types/Step'

import AgreementDetailWithProvider from './steps/AgreementDetailWithProvider'
import ChooseExchange from './steps/ChooseExchange'
import ExchangeSummary from './steps/ExchangeSummary'

export const exchangeSteps: IStep[] = [
  {
    number: 1,
    component: <ChooseExchange />,
    icon: <User />,
  },
  {
    number: 2,
    component: <AgreementDetailWithProvider />,
    icon: <Wallet />,
  },
  {
    number: 3,
    component: <ExchangeSummary />,
    icon: <Success />,
  },
]
