import {useCallback} from 'react'
import {isMobile} from 'react-device-detect'
import {useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Copy} from '../../../../../assets/icons/copy.svg'
import {ReactComponent as QRScan} from '../../../../../assets/icons/qr-scan.svg'
import Button from '../../../../../components/Button/Button'
import InfoPanel from '../../../../../components/InfoPanel/InfoPanel'
import TextInput from '../../../../../components/TextInput/TextInput'
import {REG_EX} from '../../../../../constants/regExpression'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'
import {FundsWithdrawalForm} from '../Usdt'

import styles from './styles.module.scss'

const DESTINATION_ADDRESS = 'destinationAddress'
const network = 'POLYGON'

const DestinationAddress = ({t}: WithTranslation) => {
  const {setValue, trigger} = useFormContext<FundsWithdrawalForm>()
  const {lastStep} = useWithdrawalSlice()
  const paste = useCallback(async () => {
    const copiedAddress = await navigator.clipboard.readText()
    setValue('destinationAddress', copiedAddress)
    trigger()
  }, [])
  const scan = useCallback(() => {}, [])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={() => lastStep()} />
        <span>{t('withdrawal.whichWalletDoYouWant')}</span>
      </div>
      <div className={styles.body}>
        <InfoPanel
          type="info"
          label={t('withdrawal.checkYourAddress')}
          description={t('withdrawal.checkYourAddressMessage')}
        />
        <TextInput
          label={`${t(`withdrawal.address`)} ${network}`}
          name={DESTINATION_ADDRESS}
          pattern={REG_EX.ADDRESS}
          withUseForm
          withBorder
          required
        />
        <div className={styles.buttons}>
          <Button
            className={styles.actionButton}
            styledType="outline"
            onClick={() => {
              paste()
            }}>
            <Copy /> {t('paste')}
          </Button>
          {isMobile && (
            <Button
              className={styles.actionButton}
              styledType="outline"
              onClick={() => {
                scan()
              }}>
              <QRScan /> {t('scan')}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default withTranslation()(DestinationAddress)
