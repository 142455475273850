import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as ArrowPrev} from '../../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Empty} from '../../../../../../assets/icons/empty-state.svg'
import Card from '../../../../../../components/Card/Card'
import Checkbox from '../../../../../../components/Checkbox/Checkbox'
import {IValue} from '../../../../../../components/Dropdown/Dropdown'
import Pager, {IPagerData} from '../../../../../../components/Pager/Pager'
import {PageSize, pageSizes} from '../../../../../../constants/pageSize'
import {paymentApi} from '../../../../../../services'
import {
  IBlockchainInfo,
  IPayment,
  IPaymentDetail,
  PAYMENT_DETAIL_STATUS,
} from '../../../../../../services/api/payment'
import {formatDate} from '../../../../../../utils/formatDate'
import {formatNumber} from '../../../../../../utils/formatNumber'
import DropdownFilter from '../../../../filters/DropdownFilter'
import {PANEL_SECTION} from '../../../constants'
import PaymentBox from '../../components/PaymentBox/PaymentBox'

import styles from './PaymentDetails.module.scss'

enum HEADER_TABLE {
  client = 'client',
  status = 'status',
  description = 'description',
  dueDate = 'dueDate',
  amount = 'amount',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

interface IPaymentDetails extends WithTranslation {
  payment: IPayment
  onPrev: () => void
}

const PaymentDetails = ({t, payment, onPrev}: IPaymentDetails) => {
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetail[]>()
  const [pagerData, setPagerData] = useState<IPagerData>({prev: 1, next: 1, totalPages: 1})
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [subTotal, setSubtotal] = useState<number>(0)
  const [selectedPayments, updateSelectedPayments] = useState<IPaymentDetail[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [pageSize, setPageSize] = useState<PageSize>(10)

  const getPaymentDetails = useCallback(
    async (forcedPage?: number) => {
      try {
        setFetching(true)
        const response = await paymentApi.getPaymentsDetail({
          token_id: payment.token_id.toString(),
          due_date: payment.due_date,
          page: (forcedPage || currentPage)?.toString(),
          page_size: pageSize.toString(),
        })
        setPaymentDetails(response.results)
        setPagerData({
          next: response.next,
          prev: response.previous,
          totalPages: response.total_pages,
        })
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    },
    [payment, isFetching, paymentDetails, currentPage, pagerData, pageSize],
  )

  const selectPaymentDetail = useCallback(
    (paymentDetail: IPaymentDetail, checked: boolean) => {
      if (
        checked &&
        !selectedPayments.some(selectedPayment => selectedPayment.id === paymentDetail.id)
      ) {
        updateSelectedPayments([...selectedPayments, paymentDetail])
        setSubtotal(subTotal + +paymentDetail.projected_payment_amount)
      } else if (!checked) {
        updateSelectedPayments(
          selectedPayments.filter(selectedPayment => selectedPayment.id !== paymentDetail.id),
        )
        setSubtotal(subTotal - +paymentDetail.projected_payment_amount)
      }
    },
    [selectedPayments, subTotal, setSubtotal, updateSelectedPayments],
  )

  const selectAllPaymentDetails = useCallback(
    (checked: boolean) => {
      if (!paymentDetails) return
      if (checked) {
        const filteredPayments = paymentDetails.filter(
          payment => payment.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        )
        updateSelectedPayments(filteredPayments)
        setSubtotal(
          filteredPayments.reduce(
            (accumulator, currentValue) => accumulator + +currentValue.projected_payment_amount,
            0,
          ),
        )
      } else {
        updateSelectedPayments([])
        setSubtotal(0)
      }
    },
    [
      isAllSelected,
      paymentDetails,
      updateSelectedPayments,
      setSubtotal,
      subTotal,
      selectedPayments,
    ],
  )

  const refresh = () => {
    getPaymentDetails()
    updateSelectedPayments([])
    setSubtotal(0)
  }

  const onPay = useCallback(
    () => paymentApi.payPaymentDetails(selectedPayments),
    [selectedPayments],
  )

  const onPaymentSuccess = useCallback(
    async (blockchain_info: IBlockchainInfo[]) =>
      await paymentApi.onPayDetailsSuccess(blockchain_info[0], selectedPayments),
    [selectedPayments],
  )

  useEffect(() => {
    getPaymentDetails(1)
    updateSelectedPayments([])
  }, [pageSize])

  useEffect(() => {
    if (!paymentDetails) return
    if (
      selectedPayments.length ===
        paymentDetails.filter(
          payment => payment.payment_status.toLocaleLowerCase() !== PAYMENT_DETAIL_STATUS.confirmed,
        ).length &&
      !isAllSelected
    )
      selectAll(true)
    else if (selectedPayments.length !== paymentDetails.length && isAllSelected) selectAll(false)
  }, [selectedPayments, paymentDetails])

  useEffect(() => {
    if (!currentPage || (!!currentPage && !pagerData.prev && !pagerData.next)) return
    getPaymentDetails()
    updateSelectedPayments([])
  }, [currentPage])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            <div className={styles.return} onClick={onPrev}>
              <ArrowPrev />
              <span>{t('return')}</span>
            </div>
            {`${t('panel.section.payments.detail')} ${payment.project_name.toUpperCase()}`}
          </span>
        </div>
        <div className={styles.buttons}></div>
      </div>
      <div className={styles.body}>
        <Card className={classNames(styles.card, styles.tableCard)} withShadow>
          <div className={styles.headerTable}>
            <span>{`${t('panel.section.payments.detail')}s`}</span>
            <div className={styles.pageSize}>
              <span>{t('numberOfResults')}</span>
              <DropdownFilter
                className={styles.dropdown}
                classNameContainer={styles.dropdownContainer}
                customClasses={{
                  head: styles.head,
                  body: styles.body,
                  option: styles.option,
                }}
                options={pageSizes.map(
                  page => ({key: page.toString(), label: page.toString()} as IValue),
                )}
                setValue={value => setPageSize(+value as PageSize)}
                value={{key: pageSize.toString(), label: pageSize.toString()} as IValue}
              />
            </div>
          </div>
          <table>
            <tbody>
              <tr>
                <th className={styles.check}>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={event => selectAllPaymentDetails(event?.target?.checked)}
                  />
                </th>
                {headers.map(header => (
                  <th key={header} className={styles[header]}>
                    <span className={styles[header]}>
                      {t(`panel.section.${PANEL_SECTION.payments}.header.${header}`)}
                    </span>
                  </th>
                ))}
                <th></th>
              </tr>
              {!isFetching &&
                !!paymentDetails?.length &&
                paymentDetails.map((paymentDetail: IPaymentDetail, index: number) => (
                  <tr key={index}>
                    <td className={styles.check}>
                      <Checkbox
                        checked={selectedPayments.some(
                          selectedPayment => selectedPayment.id === paymentDetail.id,
                        )}
                        disabled={
                          paymentDetail.payment_status.toLocaleLowerCase() ===
                          PAYMENT_DETAIL_STATUS.confirmed
                        }
                        onChange={event =>
                          selectPaymentDetail(paymentDetail, event?.target?.checked)
                        }
                      />
                    </td>
                    <td className={styles.client}>
                      <span>{paymentDetail.customer}</span>
                    </td>
                    <td className={styles.status}>
                      <span className={styles[paymentDetail.payment_status.toLocaleLowerCase()]}>
                        {t(
                          `panel.section.${
                            PANEL_SECTION.payments
                          }.status.${paymentDetail.payment_status.toLocaleLowerCase()}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.description}>
                      <span>
                        {t(
                          `panel.section.${PANEL_SECTION.payments}.description.${paymentDetail.description}`,
                        )}
                      </span>
                    </td>
                    <td className={styles.date}>
                      <span>{formatDate(paymentDetail.payment_date)}</span>
                    </td>
                    <td className={styles.amount}>
                      <span>$ {formatNumber(+paymentDetail.projected_payment_amount)}</span>
                    </td>
                    <td className={styles.actions}></td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(pageSize).keys()).map(skeletonRow => (
                  <tr key={skeletonRow}>
                    {Array.from(Array(headers.length + 1).keys()).map(skeleton => (
                      <td key={skeleton} style={{height: '22px'}}>
                        {skeleton !== 0 && <Skeleton width="50%" />}
                      </td>
                    ))}
                  </tr>
                ))}
              {!isFetching && !paymentDetails?.length && (
                // <tr className={styles.emptyText}>
                //   <td>{t('profile.noRecentTransaction')}</td>
                // </tr>
                <tr className={styles.emptyState}>
                  <td colSpan={7}>
                    <section>
                      <Empty />
                      <span>{t(`panel.section.${PANEL_SECTION.payments}.noPayments`)}</span>
                    </section>
                  </td>
                </tr>
              )}
            </tbody>
            {!!paymentDetails?.length && pagerData.totalPages > 1 && (
              <tfoot>
                <tr>
                  <td colSpan={headers.length + 1}>
                    <Pager
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={pagerData.totalPages}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </Card>
        <PaymentBox
          className={styles.card}
          paymentsSelected={selectedPayments.length}
          subTotal={subTotal}
          onPay={onPay}
          onPaymentSuccess={onPaymentSuccess}
          onFinishPaymentFlow={refresh}
        />
      </div>
    </>
  )
}

export default withTranslation()(PaymentDetails)
