import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {AxiosResponse} from 'axios'
import classNames from 'classnames'

import {ReactComponent as Dollar} from '../../../../../../assets/icons/dollar-minimalistic.svg'
import {ReactComponent as ErrorAlert} from '../../../../../../assets/icons/error-alert.svg'
import {ReactComponent as Success} from '../../../../../../assets/icons/success.svg'
import Button from '../../../../../../components/Button/Button'
import Card from '../../../../../../components/Card/Card'
import Modal from '../../../../../../components/Modal/Modal'
import {CURRENCY} from '../../../../../../constants/currencies'
import {useUser} from '../../../../../../contexts/UserContext'
import {useWeb3} from '../../../../../../contexts/Web3Context'
import {paymentApi} from '../../../../../../services'
import {IBlockchainInfo} from '../../../../../../services/api/payment'
import {formatNumber, formatStringToNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'
import PaymentConfirmation from '../PaymentConfirmation/PaymentConfirmation'

import styles from './PaymentBox.module.scss'

interface IPaymentBox extends WithTranslation {
  className?: string
  paymentsSelected: number
  subTotal: number
  onPay: () => any | any[]
  onPaymentSuccess: (blockchain_info: IBlockchainInfo[]) => Promise<AxiosResponse>
  onFinishPaymentFlow: () => void
  onSchedulePaymentSuccess?: () => void
}

const BACKEND_ERROR = 'Error canceling mass payment'

const PaymentBox = ({
  className,
  paymentsSelected,
  subTotal,
  onPay,
  onPaymentSuccess,
  onFinishPaymentFlow,
  // onSchedulePaymentSuccess,
  t,
}: IPaymentBox) => {
  const {web3} = useWeb3()
  const {user, balance, getBalance, fetchingBalance} = useUser()
  const [paymentModalVisible, showPaymentModal] = useState<boolean>(false)
  const [successfulPaymentModalVisible, showSuccessfulPaymentModal] = useState<boolean>(false)
  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [isApproved, setApproved] = useState<boolean>(false)
  const [isCheckingAllowance, setCheckingAllowance] = useState<boolean>(false)
  const [errorOnPaymentModalVisible, showErrorOnPaymentModal] = useState<boolean>(false)

  const sendMultipleTransaction = useCallback(
    async (index: number, txDataArray: any[], blockchainInfo: IBlockchainInfo[]) => {
      console.log('sendTransaction - index:', index, blockchainInfo)
      await web3.eth
        .sendTransaction(txDataArray[index], (error, hash) =>
          console.log('sendTransaction - callback', error, hash),
        )
        .then(({blockHash, blockNumber, status}) => {
          // number = number + 1
          blockchainInfo = [...blockchainInfo, {block_number: blockNumber, tx_hash: blockHash}]
          console.log(`Transaction data (Number ${index}):`, blockHash, blockNumber, status)
          // await paymentApi.onPaySuccess(blockNumber, blockHash, paymentsSelected)
        })
        .then(async () => {
          if (index + 1 === txDataArray.length) {
            console.log('blockchainInfo array', blockchainInfo)
            await onPaymentSuccess(blockchainInfo).catch(() => {
              showErrorOnPaymentModal(true)
              throw Error(BACKEND_ERROR)
            })
            showPaymentModal(false)
            showSuccessfulPaymentModal(true)
            return
          } else
            await setTimeout(
              async () => await sendMultipleTransaction(index + 1, txDataArray, blockchainInfo),
              2000,
            )
        })
        .catch((error: any) => {
          if (error?.code === -32603) return
          if (error?.message === BACKEND_ERROR) showErrorOnPaymentModal(true)
          console.log('Error', error)
        })
    },
    [
      showErrorOnPaymentModal,
      errorOnPaymentModalVisible,
      showPaymentModal,
      paymentModalVisible,
      showSuccessfulPaymentModal,
      successfulPaymentModalVisible,
    ],
  )

  const endConfirmation = useCallback(async () => {
    try {
      setPaymentLoading(true)
      // if (!(await hasEnoughFunds())) {
      // const {data: approveResponseData}: any = await saleApi.requestApprove(amountOfTokens || 1, token?.id)

      if (!isApproved) {
        const {
          data: {tx_data},
        }: any = await paymentApi.requestApprove(subTotal)

        await web3.eth
          .sendTransaction(tx_data)
          .on('transactionHash', (txHash: string) => {
            console.log('Transaction hash:', txHash)
          })
          .then((receipt: any) => {
            console.log('Transaction receipt:', receipt)
            setApproved(true)
          })
          .catch((error: any) => {
            if (error?.code === -32603) return
            console.error(error)
            showErrorOnPaymentModal(true)
          })
      } else {
        const {data} = await onPay()

        const txDataArray = Array.isArray(data)
          ? data.map(({tx_data}: any) => tx_data)
          : [data.tx_data]
        console.log('txDataArray', txDataArray)

        await sendMultipleTransaction(0, txDataArray, [])
      }
    } catch (error: any) {
      console.log('Error on Pay', error?.message || error)
      showErrorOnPaymentModal(true)
    } finally {
      setPaymentLoading(false)
    }
  }, [
    isApproved,
    errorOnPaymentModalVisible,
    successfulPaymentModalVisible,
    subTotal,
    isPaymentLoading,
    setPaymentLoading,
    showPaymentModal,
    paymentModalVisible,
    paymentsSelected,
  ])

  const checkAllowance = useCallback(async () => {
    try {
      setCheckingAllowance(true)
      const isAllowed = await paymentApi.allowance(+subTotal.toFixed(2))
      if (isAllowed) setApproved(isAllowed)
    } catch (error) {
      console.log('Error on checkingAllowance', error)
    } finally {
      setCheckingAllowance(false)
    }
  }, [isCheckingAllowance, setCheckingAllowance, isApproved, setApproved, subTotal])

  useEffect(() => {
    if (paymentModalVisible && !isApproved) checkAllowance()
  }, [paymentModalVisible])

  useEffect(() => {
    if (!user) return
    getBalance()
  }, [user])

  return (
    <>
      <Card className={classNames(styles.card, className)} withShadow>
        <div className={styles.box}>
          <div className={styles.header}>
            <span>{paymentsSelected}</span>
            {t(`panel.section.${PANEL_SECTION.payments}.paymentsSelected`)}
          </div>
          <div className={styles.body}>
            <div className={styles.textWrapper}>
              <span>{t(`panel.section.${PANEL_SECTION.payments}.subtotalToPay`)}</span>
              <span className={styles.amount}>$ {formatNumber(subTotal)}</span>
            </div>
            <div className={styles.textWrapper}>
              <span>{t(`panel.section.${PANEL_SECTION.payments}.availableBalance`)}</span>
              {fetchingBalance && !+balance ? (
                <Skeleton width={100} height={30} />
              ) : (
                <span className={styles.amount}>$ {balance}</span>
              )}
            </div>
          </div>
          <Button
            filledColor="primary"
            className={styles.payButton}
            disabled={!paymentsSelected || subTotal >= formatStringToNumber(balance)}
            onClick={() => showPaymentModal(true)}>
            <Dollar />
            <span>{t(`panel.section.${PANEL_SECTION.payments}.payNow`)}</span>
          </Button>
        </div>
      </Card>

      <Modal
        visible={paymentModalVisible}
        onClose={() => showPaymentModal(false)}
        outerClassName={styles.outerModal}
        containerClassName={styles.containerModal}>
        <PaymentConfirmation
          isPaymentLoading={isPaymentLoading || isCheckingAllowance}
          isApproved={isApproved}
          endConfirmation={endConfirmation}
          subTotal={subTotal}
          // taxPercentage={10}
          // fees={0}
          paymentMethod={CURRENCY.USDT}
        />
      </Modal>

      <Modal
        visible={successfulPaymentModalVisible}
        onClose={() => showSuccessfulPaymentModal(false)}
        closeButtonHidden
        outerClassName={styles.outerModal}
        containerClassName={styles.successContainerModal}
        icon={<Success />}
        title={t(`panel.section.${PANEL_SECTION.payments}.modalSuccess.title`)}
        subtitle={t(`panel.section.${PANEL_SECTION.payments}.modalSuccess.subtitle`)}
        button={{
          label: t(`panel.section.${PANEL_SECTION.payments}.returnToPanel`),
          onClick: () => {
            showSuccessfulPaymentModal(false)
            onFinishPaymentFlow()
            getBalance()
          },
        }}>
        <div className={styles.text}>
          <span>{t(`panel.section.${PANEL_SECTION.payments}.modalSuccess.paid`)}</span>
          <span className={styles.price}>$ {formatNumber(subTotal)}</span>
        </div>
      </Modal>

      <Modal
        visible={errorOnPaymentModalVisible}
        onClose={() => showErrorOnPaymentModal(false)}
        closeButtonHidden
        containerClassName={styles.errorPaymentModal}
        icon={<ErrorAlert />}
        title={t('investment.errorPayment')}
        button={{
          label: t('return'),
          onClick: () => showErrorOnPaymentModal(false),
        }}
      />
    </>
  )
}

export default withTranslation()(PaymentBox)
