import {useCallback, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Trans, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import QRCode from 'react-qr-code'

import {ReactComponent as PrevArrow} from '../../../assets/icons//arrow-prev.svg'
import {ReactComponent as Arrow} from '../../../assets/icons/arrow-next.svg'
import lemonPng from '../../../assets/icons/lemon.png'
import Button from '../../../components/Button/Button'
import {CURRENCY} from '../../../constants/currencies'
import {saleApi} from '../../../services'
import {IDepositMethodProps} from '../Deposit'

const DEPOSIT_AMOUNT = 'depositAmount'

import classNames from 'classnames'

import InfoPanel from '../../../components/InfoPanel/InfoPanel'

const enabled = false
const contact_phone = '011 0000 0000'
const email = 'support@xxx.com'

import DynamicInput from '../../../components/DynamicInput/DynamicInput'
import {REG_EX} from '../../../constants/regExpression'
import useBreakpoint from '../../../hooks/useBreakpoint'
import {
  formatNumberToString,
  formatStringToNumber,
  getDecimalSeparator,
} from '../../../utils/formatNumber'

import styles from './styles.module.scss'
const Lemon = ({investmentAmount, changeMethod, onFinishDeposit, t}: IDepositMethodProps) => {
  const breakpoint = useBreakpoint()
  const {
    control,
    trigger,
    watch,
    clearErrors,
    setValue,
    formState: {errors},
  } = useForm<{depositAmount: string}>()
  const [depositAmount] = watch(['depositAmount'])
  const [lemonUrl, setLemonUrl] = useState<string>()
  const [isLastStep, setLastStep] = useState<boolean>(false)
  const getLemonUrl = useCallback(async () => {
    const lemonUrlResponse = await saleApi.lemon(formatStringToNumber(depositAmount))
    setLemonUrl(lemonUrlResponse)
  }, [depositAmount, lemonUrl, setLemonUrl])

  const onChangeAmount = useCallback(
    (amountValue: string) => {
      const thousandSeparator = (getDecimalSeparator() === '.' && ',') || '.'
      const amountValueFormatted = amountValue.replaceAll(thousandSeparator, '')
      if (!REG_EX.DECIMAL.test(amountValueFormatted)) return
      if (
        amountValue.endsWith(getDecimalSeparator()) ||
        (amountValue.includes(getDecimalSeparator()) && amountValue.endsWith('0'))
      ) {
        setValue('depositAmount', amountValue)
        return
      }
      const originAmount = formatStringToNumber(amountValueFormatted)
      if (originAmount === 0) {
        setValue('depositAmount', '0')
        return
      }
      setValue('depositAmount', formatNumberToString(originAmount))
    },
    [setValue, depositAmount],
  )

  const onConfirmAmount = useCallback(() => {
    trigger()
    if (!depositAmount) return
    setLastStep(!isLastStep)
  }, [errors, depositAmount, isLastStep])

  useEffect(() => {
    if (isLastStep) getLemonUrl()
  }, [isLastStep])

  useEffect(() => {
    if (!!depositAmount && !!errors) clearErrors()
  }, [depositAmount])

  useEffect(() => {
    if (!!investmentAmount) setValue('depositAmount', formatNumberToString(investmentAmount + 1, 2))
  }, [investmentAmount])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow
          className={styles.prev}
          onClick={() => (isLastStep && setLastStep(!isLastStep)) || changeMethod()}
        />
        <span className={styles.title}>{t('deposit.lemon.title')}</span>
        <span className={styles.subtitle}>
          <Trans i18nKey="deposit.lemon.notLemonAccountYet">
            ¿Todavía no tienes cuenta en Lemon?
            <a href={'https://www.lemon.me/'} target="_blank" rel="noopener noreferrer">
              Creala ahora
            </a>
            .
          </Trans>
        </span>
      </div>
      <div className={styles.body}>
        {!isLastStep && (
          <>
            <InfoPanel
              type="info"
              label={t('deposit.wallet.networkAlert')}
              description={
                <Trans i18nKey="deposit.wallet.verifyDescription">
                  Asegurate de depositar <span className={styles.highlighted}>USDT</span> desde la
                  <span className={styles.highlighted}>red Polygon</span>. De lo contrario, perderás
                  tus fondos.
                </Trans>
              }
            />
            <div className={styles.depositAmount}>
              <span className={styles.question}>
                {(!!investmentAmount && t('deposit.lemon.youAreGoingToDeposit')) ||
                  t('deposit.lemon.howMuchDoYouWantToDeposit')}
              </span>
              <div className={styles.body}>
                <div className={styles.row}>
                  <Controller
                    control={control}
                    name={DEPOSIT_AMOUNT}
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, ...rest}}) => (
                      <div
                        className={classNames(styles.inputContainer, {
                          [styles.error]: !!errors[name],
                        })}>
                        <DynamicInput
                          type="string"
                          minimalFontSize={30}
                          initialFontSize={60}
                          readOnly={!!investmentAmount}
                          style={{
                            maxWidth: `${
                              `${depositAmount || 1}`.toString().length * 3 <
                              ((breakpoint !== 'xs' && 15) || 12)
                                ? `${depositAmount || 1}`.toString().length * 3
                                : (breakpoint !== 'xs' && 15) || 12
                            }rem`,
                          }}
                          {...rest}
                          onChange={e => {
                            onChangeAmount(e.target.value)
                          }}
                        />
                        <span>{CURRENCY.USDT}</span>
                      </div>
                    )}
                  />
                </div>
                <div className={styles.line} />
                <InfoPanel
                  className={styles.info}
                  type="info"
                  label={t('deposit.lemon.comission')}
                  description={
                    <Trans i18nKey="deposit.lemon.comissionMessage">
                      Recordá que un <span className={styles.currency}>1 USDT</span> extra es para
                      pagar las comisiones de Lemon.
                    </Trans>
                  }
                />
              </div>
              <div className={styles.depositMethod}>
                <div className={styles.label}>{t('deposit.depositMethod')}</div>
                <div className={styles.method}>
                  <span>
                    <div className={styles.circle}>
                      <img src={lemonPng} alt="lemonIcon" />
                    </div>
                    {`${t('deposit.lemon.methodTitle')} Cash`}
                  </span>

                  <div className={styles.changeMethod} onClick={() => changeMethod()}>
                    {t('deposit.changeMethod')}
                    <Arrow />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isLastStep && (
          <>
            <div className={styles.qr}>
              <div className={styles.box}>
                {!!lemonUrl ? (
                  <QRCode level="L" value={lemonUrl} />
                ) : (
                  <Skeleton height="262px" width="262px" />
                )}
              </div>
              <span>
                <Trans i18nKey="deposit.lemon.qrLabel">
                  Escaneá el QR o
                  <a href={lemonUrl} target="_blank" rel="noopener noreferrer">
                    clickea acá
                  </a>
                  para abrir directamente la app de <span>Lemon Cash</span> con tu celular
                </Trans>
              </span>
            </div>
            {enabled && (
              <InfoPanel
                type="info"
                label={t('deposit.lemon.transactionConfirmed')}
                description={t('deposit.lemon.transactionConfirmedMessage', {
                  contact_phone: `${contact_phone}`,
                  email: `${email}`,
                })}
              />
            )}
          </>
        )}
      </div>
      <Button
        type="button"
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={() => {
          if (isLastStep) onFinishDeposit()
          else onConfirmAmount()
        }}>
        {(isLastStep && t('deposit.iAlreadyDeposit')) || t('continue')}
      </Button>
    </>
  )
}

export default withTranslation()(Lemon)
