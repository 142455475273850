import {ReactNode} from 'react'

import BackID from './BackID'
import CuilCuitCivilStatus from './CuilCuitCivilStatus'
import FrontID from './FrontID'
import SupportingDocuments from './SupportingDocuments'

export const steps: ReactNode[] = [
  <CuilCuitCivilStatus key={1} />,
  <FrontID key={2} />,
  <BackID key={3} />,
  <SupportingDocuments key={4} />,
]
