import {useCallback, useEffect, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Download} from '../../../../assets/icons/download.svg'
import {ReactComponent as Refresh} from '../../../../assets/icons/refresh.svg'
import emptyPermutoken from '../../../../assets/images/no-permutoken.png'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import {IValue} from '../../../../components/Dropdown/Dropdown'
import Pager, {IPagerData} from '../../../../components/Pager/Pager'
import {CURRENCY} from '../../../../constants/currencies'
import {PageSize, pageSizes} from '../../../../constants/pageSize'
import {PATHS} from '../../../../constants/paths'
import {permutokenApi} from '../../../../services'
import {IExchangeValue} from '../../../../services/api/permutoken'
import Config from '../../../../utils/config'
import {formatNumber} from '../../../../utils/formatNumber'
import DropdownFilter from '../../filters/DropdownFilter'
import {PANEL_SECTION} from '../constants'

import styles from './Permutoken.module.scss'

enum HEADER_TABLE {
  project = 'project',
  tokenAmount = 'tokenAmount',
  tokenPrice = 'tokenPrice',
  freeTokens = 'freeTokens',
  exchangeStatus = 'exchangeStatus',
  agreement = 'agreement',
}
const headers: (keyof typeof HEADER_TABLE)[] = Object.values(HEADER_TABLE)

const Permutoken = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [permutokens, setPermutokens] = useState<IExchangeValue[]>()
  const [pagerData, setPagerData] = useState<IPagerData>({prev: 1, next: 1, totalPages: 1})
  const [isFetching, setFetching] = useState<boolean>(false)
  const [isAllSelected, selectAll] = useState<boolean>(false)
  const [selectedPermutokens, updateSelectedPermutokens] = useState<IExchangeValue[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [pageSize, setPageSize] = useState<PageSize>(10)

  const getPermutokens = useCallback(
    async (forcedPage?: number) => {
      try {
        setFetching(true)
        const response = await permutokenApi.getExchanges(forcedPage || currentPage)
        setPermutokens(response.results)
        setPagerData({
          next: response.next,
          prev: response.previous,
          totalPages: response.total_pages,
        })
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    },
    [permutokens, setPermutokens, pagerData, setPagerData, currentPage, pageSize],
  )

  const selectPermutoken = useCallback(
    (permutoken: IExchangeValue, checked: boolean) => {
      if (
        checked &&
        !selectedPermutokens.some(selectedToken => selectedToken.id === permutoken.id)
      ) {
        updateSelectedPermutokens([...selectedPermutokens, permutoken])
      } else if (!checked) {
        updateSelectedPermutokens(
          selectedPermutokens.filter(selectedToken => selectedToken.id !== permutoken.id),
        )
      }
    },
    [selectedPermutokens, updateSelectedPermutokens],
  )

  const selectAllPermutokens = useCallback(
    (checked: boolean) => {
      if (!permutokens) return
      if (checked) updateSelectedPermutokens(permutokens)
      else updateSelectedPermutokens([])
    },
    [isAllSelected, permutokens, updateSelectedPermutokens, selectedPermutokens],
  )

  useEffect(() => {
    getPermutokens(1)
    updateSelectedPermutokens([])
  }, [pageSize])

  useEffect(() => {
    if (!permutokens) return
    if (
      permutokens.length > 0 &&
      selectedPermutokens.length === permutokens.length &&
      !isAllSelected
    )
      selectAll(true)
    else if (selectedPermutokens.length !== permutokens.length && isAllSelected) selectAll(false)
  }, [selectedPermutokens, permutokens])

  useEffect(() => {
    if (!currentPage || (!!currentPage && !pagerData.prev && !pagerData.next)) return
    getPermutokens()
  }, [currentPage])

  if (!Config.isPermutokenEnabled) {
    navigate(-1)
    return null
  }

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.textWrapper}>
          <span className={styles.title}>
            {t(`panel.section.${PANEL_SECTION.permutoken}.title`)}
          </span>
          <span className={styles.subtitle}>
            {t(`panel.section.${PANEL_SECTION.permutoken}.myExchanges`)}
          </span>
        </div>
        <Button
          className={styles.exchange}
          filledColor="primary"
          onClick={() => navigate(PATHS.EXCHANGE)}>
          <Refresh />
          {t(`panel.section.${PANEL_SECTION.permutoken}.exchange`)}
        </Button>
      </div>
      <div className={styles.body}>
        {(!isFetching && !permutokens?.length && (
          <section className={styles.emptyState}>
            <img src={emptyPermutoken} />
            <div className={styles.header}>
              {t(`panel.section.${PANEL_SECTION.permutoken}.noTokens`)}
            </div>
            <span className={styles.footer}>
              <Trans i18nKey={`panel.section.${PANEL_SECTION.permutoken}.makeAgreement`}>
                Realizá tu primer
                <span onClick={() => navigate(PATHS.EXCHANGE)}>Acuerdo de canje.</span>
              </Trans>
            </span>
          </section>
        )) || (
          <>
            <span className={styles.items}>
              <span>{selectedPermutokens.length}</span>
              {t(`panel.section.${PANEL_SECTION.permutoken}.tokensSelected`)}
            </span>
            <Card className={classNames(styles.card)} withShadow>
              <div className={styles.headerTable}>
                <span>{t(`panel.section.${PANEL_SECTION.permutoken}.title`)}</span>
                <div className={styles.pageSize}>
                  <span>{t('numberOfResults')}</span>
                  <DropdownFilter
                    className={styles.dropdown}
                    classNameContainer={styles.dropdownContainer}
                    customClasses={{
                      head: styles.head,
                      body: styles.body,
                      option: styles.option,
                    }}
                    options={pageSizes.map(
                      page => ({key: page.toString(), label: page.toString()} as IValue),
                    )}
                    setValue={value => setPageSize(+value as PageSize)}
                    value={{key: pageSize.toString(), label: pageSize.toString()} as IValue}
                  />
                </div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <th className={styles.check}>
                      <Checkbox
                        checked={isAllSelected}
                        onChange={event => selectAllPermutokens(event?.target?.checked)}
                      />
                    </th>
                    {headers.map(header => (
                      <th key={header} className={styles[header]}>
                        <span className={styles[header]}>
                          {t(`panel.section.${PANEL_SECTION.permutoken}.header.${header}`)}
                        </span>
                      </th>
                    ))}
                  </tr>
                  {!isFetching &&
                    !!permutokens?.length &&
                    permutokens.map((permutoken: IExchangeValue) => (
                      <tr key={permutoken.id}>
                        <td className={styles.check}>
                          <Checkbox
                            checked={selectedPermutokens.some(
                              selectAllPayment => selectAllPayment.id === permutoken.id,
                            )}
                            onChange={event => selectPermutoken(permutoken, event?.target?.checked)}
                          />
                        </td>
                        <td className={styles.name}>
                          <span>{permutoken.token.name}</span>
                        </td>
                        <td>
                          <span>
                            {permutoken.total_tokens} Token{permutoken.total_tokens > 1 && 's'}
                          </span>
                        </td>
                        <td>
                          <span>
                            {formatNumber(permutoken.total_paid)} {CURRENCY.USDT}
                          </span>
                        </td>
                        <td>
                          <span>
                            {permutoken.tokens_released} Token
                            {permutoken.tokens_released > 1 && 's'}
                          </span>
                        </td>
                        <td className={styles.exchangeStatus}>
                          <span className={styles[permutoken.status.toLocaleLowerCase()]}>
                            {t(
                              `panel.section.${
                                PANEL_SECTION.permutoken
                              }.status.${permutoken.status.toLocaleLowerCase()}`,
                            )}
                          </span>
                        </td>
                        <td>
                          <a
                            className={styles.downloadLinkButton}
                            target="_blank"
                            rel="noopener noreferrer"
                            // href={reserve.receipt + '?download=true'}
                            href={permutoken.file_url}
                            data-ipfs
                            download="Acuerdo">
                            <Download className={styles.download} />
                          </a>
                        </td>
                      </tr>
                    ))}
                  {isFetching &&
                    Array.from(Array(pageSize).keys()).map(skeletonRow => (
                      <tr key={skeletonRow}>
                        {Array.from(Array(headers.length + 1).keys()).map(skeleton => (
                          <td key={skeleton} style={{height: '22px'}}>
                            {skeleton !== 0 && skeleton !== 2 && <Skeleton width="50%" />}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
                {!!permutokens?.length && pagerData.totalPages > 1 && (
                  <tfoot>
                    <tr>
                      <td colSpan={headers.length + 1}>
                        <Pager
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPages={pagerData.totalPages}
                        />
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </Card>
          </>
        )}
      </div>
    </div>
  )
}

export default withTranslation()(Permutoken)
