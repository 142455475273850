import {ReactNode} from 'react'
import {create, StateCreator} from 'zustand'

import {
  WITHDRAWAL_PESOS_STEPS,
  withdrawalPesosSteps,
} from '../../pages/withdrawal/methods/pesos/steps/steps'
import withdrawalUsdtSteps from '../../pages/withdrawal/methods/usdt/steps/usdtSteps'
import {BankAccount} from '../../services/interfaces/IMantecaWithdraw'

export interface IWithdrawalData {
  cbu_alias?: string
  name?: string
  bankAccountChosen?: BankAccount | IChosenAccount | null
}

export interface IChosenAccount extends BankAccount {
  account_index: number
}
export interface WithdrawalSlice {
  type?: 'pesos' | 'usdt'
  activeStepIndex: number
  withdrawalSteps: ReactNode[] | []
  withdrawalData: IWithdrawalData | undefined
  setWithdrawalMethod: (type: 'pesos' | 'usdt' | undefined) => void
  initSteps: (type: 'pesos' | 'usdt') => void
  initPesosIndex: (withAccounts: boolean) => void
  goToStep: (stepIndex: number) => void
  resetWithdrawal: () => void
  lastStep: () => void
  nextStep: () => void
  updateWithdrawalData: (withdrawalData: IWithdrawalData) => void
}

const createWithdrawalSlice: StateCreator<WithdrawalSlice> = (set, get) => ({
  withdrawalSteps: [],
  activeStepIndex: 0,
  withdrawalData: undefined,
  lastStep: () => {
    const {activeStepIndex} = get()
    if (activeStepIndex === 0) return
    set({activeStepIndex: activeStepIndex - 1})
  },
  nextStep: () => {
    const {activeStepIndex, withdrawalSteps: steps} = get()
    if (activeStepIndex + 1 === steps.length) return
    set({activeStepIndex: activeStepIndex + 1})
  },
  initSteps: (type: 'pesos' | 'usdt') => {
    set({
      withdrawalSteps: type === 'pesos' ? withdrawalPesosSteps : withdrawalUsdtSteps,
      activeStepIndex: 0,
    })
  },
  initPesosIndex: (withAccounts: boolean) => {
    set({
      activeStepIndex:
        (withAccounts && WITHDRAWAL_PESOS_STEPS.CHOOSE_ACCOUNT) ||
        WITHDRAWAL_PESOS_STEPS.ADD_ACCOUNT,
    })
  },
  setWithdrawalMethod: (type: 'pesos' | 'usdt' | undefined) => {
    set({type: type})
  },
  updateWithdrawalData: (withdrawal: IWithdrawalData) => {
    set(state => ({withdrawalData: {...state.withdrawalData, ...withdrawal}}))
  },
  resetWithdrawal: () => set({type: undefined, withdrawalSteps: []}),
  goToStep: (stepIndex: number) => set({activeStepIndex: stepIndex}),
})

export const useWithdrawalSlice = create<WithdrawalSlice>()((...a) => ({
  ...createWithdrawalSlice(...a),
}))
