const getBaseUrl = () => {
  return (
    process.env.REACT_APP_API_URL ||
    'https://2mqhv6ea4e.execute-api.us-west-2.amazonaws.com/api/v1/'
  )
}

const Config = {
  baseUrlApi: getBaseUrl(),
  isProduction: process.env.REACT_APP_NODE_ENV === 'production',
  isKycEnabled: process.env.REACT_APP_KYC_ENABLED === 'true',
  isMantecaEnabled: process.env.REACT_APP_MANTECA_ENABLED === 'true',
  isPermutokenEnabled: process.env.REACT_APP_PERMUTOKEN_ENABLED === 'true',
}

export default Config
