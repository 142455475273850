import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {ReactComponent as Alert} from '../../assets/icons/alert.svg'
import {ReactComponent as PrevArrow} from '../../assets/icons/arrow-prev.svg'
import Modal from '../../components/Modal/Modal'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useIdentityValidationSlice} from '../../store/slices/identityValidation'
import Config from '../../utils/config'

import styles from './IdentityValidation.module.scss'
const IdentityValidation = ({t}: WithTranslation) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const {steps, activeStepIndex, lastStep, isUserValidated, checkIdentityValidation} =
    useIdentityValidationSlice()
  const [modalVisible, hideModal] = useState<boolean>(true)

  useEffect(() => {
    if ((Config.isMantecaEnabled && isUserValidated) || !Config.isMantecaEnabled) return
    checkIdentityValidation()
  }, [])

  if (!Config.isMantecaEnabled) {
    navigate(-1)
    return null
  }
  if (isUserValidated) navigate(state?.pathOnValidationFinished || PATHS.PORTFOLIO)

  return (
    <>
      <BannerSection className={styles.section}>
        <BannerContainer className={styles.container} layout="column">
          <div className={styles.header}>
            <PrevArrow
              className={styles.prev}
              onClick={() =>
                (activeStepIndex === 0 && navigate(state?.pathOnValidationFinished || -1)) ||
                lastStep()
              }
            />
            <span>{t('identityValidation.title')}</span>
          </div>
          <section className={styles.body}>{steps[activeStepIndex]}</section>
        </BannerContainer>
      </BannerSection>

      <Modal
        visible={modalVisible}
        onClose={() => hideModal(false)}
        closeButtonHidden
        outerClassName={styles.modal}
        icon={<Alert />}
        title={t('identityValidation.modalAlert.title')}
        subtitle={t('identityValidation.modalAlert.subtitle')}
        button={{
          label: t('continue'),
          onClick: () => hideModal(false),
        }}
      />
    </>
  )
}

export default withTranslation()(IdentityValidation)
