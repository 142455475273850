export const formatNumber = (
  value?: number,
  {minimumFractionDigits = 0, maximumFractionDigits = 2} = {},
): string =>
  value?.toLocaleString('de-DE', {
    minimumFractionDigits,
    maximumFractionDigits,
  }) || ''

export const getDecimalSeparator = (): string => {
  const {format} = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    useGrouping: true,
  })
  return (
    /^0(.)1$/.exec(format(0.1))?.find(element => element === '.' || element === ',') ||
    DECIMAL_SEPARATOR
  )
}
export const hasDecimalSeparator = (value: number): boolean => {
  const {format} = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
    useGrouping: true,
  })
  return !!/^0(.)1$/.exec(format(value))?.find(element => element === '.' || element === ',')
}

export const DECIMAL_SEPARATOR = ','
export const THOUSAND_SEPARATOR = '.'

export const formatStringToNumber = (value: string): number =>
  parseFloat(value?.replaceAll(/[,.]/g, m => (m === getDecimalSeparator() ? '.' : '')))

export const formatNumberToString = (value: number, maximumFractionDigits = 5): string => {
  if (!value && value !== 0) return ''

  const {format} = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: true,
  })

  return format(value)
}
