import {useCallback, useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import DynamicInput from '../../../../../components/DynamicInput/DynamicInput'
import {CURRENCY} from '../../../../../constants/currencies'
import {REG_EX} from '../../../../../constants/regExpression'
import {useUser} from '../../../../../contexts/UserContext'
import {useWeb3} from '../../../../../contexts/Web3Context'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'
import {
  formatNumberToString,
  formatStringToNumber,
  getDecimalSeparator,
} from '../../../../../utils/formatNumber'
import {FundsWithdrawalForm} from '../Usdt'

import styles from './styles.module.scss'

const FUND_AMOUNT = 'fundAmount'

const FundAmount = ({t}: WithTranslation) => {
  const {resetWithdrawal} = useWithdrawalSlice()
  const {
    control,
    formState: {errors},
    setValue,
  } = useFormContext<FundsWithdrawalForm>()
  const {user, balance, getBalance, fetchingBalance} = useUser()
  const {web3} = useWeb3()

  const onChangeAmount = useCallback(
    (amountValue: string) => {
      const thousandSeparator = (getDecimalSeparator() === '.' && ',') || '.'
      const amountValueFormatted = amountValue.replaceAll(thousandSeparator, '')
      if (!REG_EX.DECIMAL.test(amountValueFormatted)) return
      if (
        amountValue.endsWith(getDecimalSeparator()) ||
        (amountValue.includes(getDecimalSeparator()) && amountValue.endsWith('0'))
      ) {
        setValue('fundAmount', amountValue)
        return
      }
      const originAmount = formatStringToNumber(amountValueFormatted)
      if (originAmount === 0) {
        setValue('fundAmount', '0')
        return
      }
      setValue('fundAmount', formatNumberToString(originAmount))
    },
    [setValue],
  )

  useEffect(() => {
    if (!user || !web3) return
    getBalance()
  }, [user, web3])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={() => resetWithdrawal()} />
        <span>{t('withdrawal.howMuchDoYouWantToWithdraw')}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          <Controller
            control={control}
            name={FUND_AMOUNT}
            rules={{
              required: true,
            }}
            render={({field: {name, ...rest}}) => (
              <div
                className={classNames(styles.inputContainer, {
                  [styles.error]: !!errors[name],
                })}>
                <DynamicInput
                  type="string"
                  {...rest}
                  minimalFontSize={30}
                  initialFontSize={60}
                  onChange={e => {
                    onChangeAmount(e.target.value)
                  }}
                />
                <span>{CURRENCY.USDT}</span>
              </div>
            )}
          />
        </div>
        <div className={styles.line} />
        <span className={styles.available}>
          <span>{t('withdrawal.available')}</span>
          {fetchingBalance ? (
            <Skeleton width={70} height={15} />
          ) : (
            <>
              {balance} {CURRENCY.USDT}
            </>
          )}
        </span>
      </div>
    </>
  )
}

export default withTranslation()(FundAmount)
