import React, {Dispatch, useContext, useEffect, useState} from 'react'

import {USDT_ABI} from '../constants/abis'
import {getProvider} from '../libs/provider'
import {mantecaApi} from '../services'
import {BankAccount} from '../services/interfaces/IMantecaWithdraw'
import Config from '../utils/config'
import {formatNumberToString} from '../utils/formatNumber'
import {logout} from '../utils/logout'

import {useWeb3} from './Web3Context'

export const UserContext = React.createContext<any>([])

const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS as string

export const UserProvider = ({children}: any) => {
  const {web3, setWeb3} = useWeb3()
  const [user, setUser] = useState<string | undefined>(localStorage.getItem('user') || undefined)
  const [balance, setBalance] = useState<string>('0')
  const [maticBalance, setMaticBalance] = useState<string>('0')
  const [pesosBalance, setPesosBalance] = useState<string>('0')
  const [pesosBankAccounts, setPesosBankAccounts] = useState<BankAccount[]>([])
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(true)
  const [fetchingMaticBalance, setFetchingMaticBalance] = useState<boolean>(true)
  const [fetchingPesosBalance, setFetchingPesosBalance] = useState<boolean>(true)
  const [fetchingAccounts, setFetchingAccounts] = useState<boolean>(true)

  const handleUserOnPageLoad = async () => {
    const provider = await getProvider()
    const accounts = await provider.request({method: 'eth_accounts'})
    // If user wallet is no longer connected, logout
    if (!accounts[0] && user) {
      logout(setWeb3, setUser)
    }
    // Set user in localStorage, or clear localStorage if no wallet connected
    accounts[0] ? localStorage.setItem('user', accounts[0]) : localStorage.removeItem('user')
    setUser(accounts[0])
  }

  const getBalance = async () => {
    try {
      setFetchingBalance(true)
      if (!!user && !!web3) {
        const contract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS)
        const weiBalance = await contract.methods.balanceOf(user).call()
        setBalance(formatNumberToString(+web3.utils.fromWei(weiBalance, 'mwei'), 3) || '0')
      }
    } catch (error) {
      console.error('Error getting balance', error)
    } finally {
      setFetchingBalance(false)
    }
  }

  const getMaticBalance = async () => {
    try {
      setFetchingMaticBalance(true)
      if (!!user && !!web3) {
        const weiBalance = await web3.eth.getBalance(user)
        setMaticBalance(formatNumberToString(+web3.utils.fromWei(weiBalance), 3) || '0')
      }
    } catch (error) {
      console.error('Error getting matic balance', error)
    } finally {
      setFetchingMaticBalance(false)
    }
  }

  const getPesosBalance = async () => {
    try {
      setFetchingPesosBalance(true)
      if (!!user && Config.isMantecaEnabled) {
        const balance = await mantecaApi.getPesosBalance()
        setPesosBalance(formatNumberToString(+balance, 3) || '0')
      }
    } catch (error) {
      console.error('Error getting pesos balance', error)
    } finally {
      setFetchingPesosBalance(false)
    }
  }

  const getPesosAccounts = async () => {
    try {
      if (!fetchingAccounts) setFetchingAccounts(true)
      if (!!user && Config.isMantecaEnabled) {
        const pesosBankAccounts = await mantecaApi.getPesosAccounts()
        setPesosBankAccounts(pesosBankAccounts)
      }
    } catch (error) {
      console.error('Error getting pesos balance', error)
    } finally {
      setFetchingAccounts(false)
    }
  }

  const value = React.useMemo(
    () => ({
      user,
      setUser,
      getBalance,
      balance,
      getMaticBalance,
      maticBalance,
      getPesosBalance,
      pesosBalance,
      fetchingBalance,
      fetchingMaticBalance,
      fetchingPesosBalance,
      getPesosAccounts,
      pesosBankAccounts,
      fetchingAccounts,
    }),
    [
      user,
      setUser,
      getBalance,
      balance,
      getMaticBalance,
      maticBalance,
      getPesosBalance,
      pesosBalance,
      getPesosAccounts,
      pesosBankAccounts,
      fetchingAccounts,
      fetchingBalance,
      fetchingMaticBalance,
      fetchingPesosBalance,
    ],
  )

  useEffect(() => {
    handleUserOnPageLoad()
  }, [])

  return <UserContext.Provider value={{...value}}>{children}</UserContext.Provider>
}

export const useUser = (): {
  user: string | undefined
  setUser: Dispatch<string>
  getBalance: Dispatch<void>
  balance: string
  getMaticBalance: Dispatch<void>
  maticBalance: string
  getPesosBalance: Dispatch<void>
  pesosBalance: string
  fetchingBalance: boolean
  fetchingMaticBalance: boolean
  fetchingPesosBalance: boolean
  getPesosAccounts: Dispatch<void>
  pesosBankAccounts: BankAccount[]
  fetchingAccounts: boolean
} => {
  return useContext(UserContext)
}
