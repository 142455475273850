import {ReactNode} from 'react'
import {create, StateCreator} from 'zustand'

import {steps} from '../../pages/identityValidation/steps/steps'
import {mantecaApi} from '../../services'

export interface FileData {
  file: FormData
  fileName: string
  error?: boolean
  success?: boolean
}

export interface IValidationData {
  legal_id?: string
  civil_status?: string
  frontIDAttachment?: FormData | undefined
  backIDAttachment?: FormData | undefined
  fundsAttachments?: FileData[]
}
export interface IdentityValidationSlice {
  activeStepIndex: number
  steps: ReactNode[]
  identityValidationData: IValidationData | undefined
  isUserValidated: boolean
  validating: boolean
  lastStep: () => void
  nextStep: () => void
  checkIdentityValidation: () => void
  updateIdentityValidationData: (validationData: IValidationData) => void
}

const createIdentityValidationSlice: StateCreator<IdentityValidationSlice> = (set, get) => ({
  steps: steps,
  activeStepIndex: 0,
  identityValidationData: undefined,
  isUserValidated: false,
  validating: false,
  lastStep: () => {
    const {activeStepIndex} = get()
    if (activeStepIndex === 0) return
    set({activeStepIndex: activeStepIndex - 1})
  },
  nextStep: () => {
    const {activeStepIndex, steps} = get()
    if (activeStepIndex + 1 === steps.length) return
    set({activeStepIndex: activeStepIndex + 1})
  },
  checkIdentityValidation: async () => {
    try {
      set({
        validating: true,
      })
      const isValidated = await mantecaApi.isUserValidated()
      set({
        isUserValidated: isValidated,
      })
    } catch (error) {
    } finally {
      set({
        validating: false,
      })
    }
  },
  updateIdentityValidationData: (validationData: IValidationData) => {
    set(state => ({identityValidationData: {...state.identityValidationData, ...validationData}}))
  },
})

export const useIdentityValidationSlice = create<IdentityValidationSlice>()((...a) => ({
  ...createIdentityValidationSlice(...a),
}))
