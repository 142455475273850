import {CurrencyType} from '../../constants/currencies'
import {PaymentStatus} from '../api/payment'
import {IOwner} from '../api/sale'

import {PaymentMethod} from './IToken'

export enum SIGNATURE_STATUS {
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SIGNED = 'SIGNED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

export type SignatureStatus = keyof typeof SIGNATURE_STATUS

export interface ISignature {
  status: SignatureStatus
  envelope_id: string
  url?: string
}
export interface IReserveToken {
  name: string
  nft_contract_address: string
  ipfs: string
  price: string
  url: string
  currency: CurrencyType
}
export interface IReserve {
  id: number
  token: IReserveToken
  owner: IOwner
  signature: ISignature
  token_amount: number
  total_paid: number
  payment_method: PaymentMethod
  payment_status: PaymentStatus
  receipt: string
  APR: number
  created_at: string
}
export type IDocumentation = IReserve
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISale extends IReserve {}
