import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Alert} from '../../../../assets/icons/alert.svg'
import Modal from '../../../../components/Modal/Modal'
import BannerContainer from '../../../../containers/BannerContainer'
import {useUser} from '../../../../contexts/UserContext'
import {useIdentityValidationSlice} from '../../../../store/slices/identityValidation'
import {useWithdrawalSlice} from '../../../../store/slices/withdrawal'
import Config from '../../../../utils/config'

import styles from '../methods.module.scss'
const Pesos = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {pesosBankAccounts, fetchingAccounts} = useUser()
  const {checkIdentityValidation, isUserValidated} = useIdentityValidationSlice()
  const {withdrawalSteps, activeStepIndex} = useWithdrawalSlice()
  const [accountModalVisible, showAccountsModal] = useState<boolean>(
    !fetchingAccounts && !pesosBankAccounts.length,
  )

  useEffect(() => {
    if (isUserValidated) return
    checkIdentityValidation()
  }, [])

  if (!Config.isMantecaEnabled) {
    navigate(-1)
    return null
  }

  return (
    <>
      <BannerContainer className={styles.container} layout="column">
        <section className={styles.form}>{withdrawalSteps[activeStepIndex]}</section>
      </BannerContainer>

      <Modal
        visible={accountModalVisible}
        onClose={() => showAccountsModal(false)}
        closeButtonHidden
        outerClassName={styles.accountModal}
        containerClassName={styles.containerModal}
        icon={<Alert />}
        title={t('bankAccount.modal.addAccount')}
        subtitle={t('bankAccount.modal.message')}
        button={{
          label: t('continue'),
          onClick: () => showAccountsModal(false),
        }}
      />
    </>
  )
}

export default withTranslation()(Pesos)
