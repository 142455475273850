import {forwardRef, ForwardRefRenderFunction, HTMLInputTypeAttribute} from 'react'
import {Controller, useFormContext, ValidationRule} from 'react-hook-form'
import classNames from 'classnames'

import {ReactComponent as Date} from '../../assets/icons/date.svg'

import styles from './TextInput.module.scss'

interface ITextInputProps {
  className?: string
  type?: HTMLInputTypeAttribute
  label?: string | null
  name?: string | null
  placeholder?: string | null
  pattern?: ValidationRule<RegExp>
  filledColor?: 'primary' | 'secondary' | 'light' | 'transparent' | 'white'
  withBorder?: boolean
  underlined?: boolean
  withUseForm?: boolean
  required?: boolean
  disabled?: boolean
  min?: string | number
  max?: string | number
}
const TextInput: ForwardRefRenderFunction<any, ITextInputProps> = (
  {
    className,
    type = 'text',
    label,
    name,
    placeholder,
    pattern,
    withBorder = false,
    underlined = false,
    withUseForm = false,
    required = false,
    filledColor = 'white',
    disabled,
    min,
    max,
  }: ITextInputProps,
  inputRef,
) => {
  const {
    control,
    formState: {errors},
  } = (withUseForm && useFormContext()) || {formState: {}}
  return (
    <div className={classNames(styles.container, className, {[styles.underlined]: underlined})}>
      {label && (
        <span
          className={classNames(styles.label, {
            [styles.error]: !!errors && !!errors[name || ''],
          })}>
          {label}
        </span>
      )}
      {withUseForm ? (
        <Controller
          control={control}
          name={name || ''}
          rules={{
            required: required,
            // {value: required, message: t('')},
            pattern: pattern,
          }}
          render={({field: {name, onChange, ...rest}}) => (
            <>
              <div
                className={classNames(styles.box, styles[`background-${filledColor}`], {
                  [styles.withBorder]: withBorder,
                  [styles.underlined]: underlined,
                  [styles.error]: !!errors && !!errors[name],
                })}>
                <input
                  type={type}
                  min={min}
                  max={max}
                  placeholder={placeholder || ''}
                  disabled={disabled}
                  onChange={event => {
                    if (
                      !!pattern &&
                      !pattern.toString().includes('+@') &&
                      !(pattern as RegExp).test(event.target.value)
                    ) {
                      if (
                        event.target.value !== undefined &&
                        event.target.value !== null &&
                        event.target.value !== '' &&
                        !event.target.value.endsWith('.')
                      ) {
                        event.preventDefault()
                        return
                      }
                    }
                    onChange(event)
                  }}
                  {...rest}
                  ref={inputRef}
                />
                {type === 'date' && (
                  <Date
                    className={styles.date}
                    onClick={() => (inputRef as any)?.current.showPicker()}
                  />
                )}
              </div>
              {errors && errors[name] && (
                <div
                  className={classNames(styles.errorContainer, {
                    [styles.error]: !!errors && !!errors[name],
                  })}>
                  <div role="alert" className={styles.errorLabel}>
                    {errors[name]?.message?.toString()}
                  </div>
                </div>
              )}
            </>
          )}
        />
      ) : (
        <>
          <div
            className={classNames(styles.box, styles[`background-${filledColor}`], {
              [styles.withBorder]: withBorder,
              [styles.underlined]: underlined,
            })}>
            <input
              type={type}
              min={min}
              max={max}
              placeholder={placeholder || ''}
              disabled={disabled}
              ref={inputRef}
            />
            {type === 'date' && (
              <Date
                className={styles.date}
                onClick={() => (inputRef as any)?.current.showPicker()}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default forwardRef(TextInput)
