import axios from '../../core/axios'
import {addUrlParam} from '../../utils/addUrlParam'
import {IProvider} from '../interfaces/IProvider'
import {IToken} from '../interfaces/IToken'
export type EnchangeType = 'MATERIALS' | 'SERVICES'
export interface IExchange {
  token: number | IToken
  exchange_type: EnchangeType
  comments?: string
  total_paid: number
  file_url: string
  vesting?: boolean
  total_days?: number
}

export interface IExchangeValue extends IExchange {
  id: number
  token: IToken
  total_tokens: number
  status: ExchangeStatus
  created_at: string
  modified_at: string
  tokens_released: number
}

export enum EXCHANGE_STATUS {
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}
export type ExchangeStatus = keyof typeof EXCHANGE_STATUS

export interface IExchangesData {
  count: number
  next: number
  previous: number
  total_pages: number
  results: IExchangeValue[]
}

export default {
  createExchange: async (exchangeData: IExchange): Promise<IProvider> => {
    const {data: responseData} = await axios.post('permutoken/exchange/', exchangeData)
    return responseData
  },
  getExchanges: async (currentPage?: number): Promise<IExchangesData> => {
    let basePath = 'permutoken/'
    if (!!currentPage) basePath = addUrlParam(basePath, 'page', currentPage)
    const {data: responseData} = await axios.get(basePath)
    return responseData as IExchangesData
  },
}
