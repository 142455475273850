import {useState} from 'react'
import {withTranslation} from 'react-i18next'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as PrevArrow} from '../../../assets/icons//arrow-prev.svg'
import {ReactComponent as Copy} from '../../../assets/icons/copy.svg'
import Button from '../../../components/Button/Button'
import InfoPanel from '../../../components/InfoPanel/InfoPanel'
import {CURRENCY} from '../../../constants/currencies'
import {IDepositMethodProps} from '../Deposit'

import styles from './styles.module.scss'

const CBU_INDEX = 0
const ALIAS_INDEX = 1
const MANTECA_ALIAS = 'manteca.fijo.ht'
const MANTECA_CBU = '012345678910111213141516'

const Pesos = ({changeMethod, t}: IDepositMethodProps) => {
  const [copiedValues, showCopiedValues] = useState<boolean[]>([false, false])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={() => changeMethod()} />
        <span className={styles.title} style={{width: '100%'}}>
          {t('deposit.pesos.title')}
        </span>
        <span className={styles.subtitle} style={{width: '90%'}}>
          {t('deposit.pesos.subtitle')}
        </span>
      </div>
      <div className={classNames(styles.propertiesContainer, styles.line)}>
        <div className={styles.property}>
          <span>{t('deposit.pesos.currency')}</span>
          <span>{CURRENCY.ARS}</span>
        </div>
        <div className={classNames(styles.property, styles.column)}>
          <div className={styles.copyRow}>
            <span>{t('deposit.pesos.cbu')}</span>
            {(copiedValues[CBU_INDEX] && <span>{t('copied')}</span>) || (
              <>
                <span className={styles.value}>{MANTECA_CBU}</span>
                <Copy
                  onClick={() => {
                    showCopiedValues([...copiedValues.map((_, index) => index === CBU_INDEX)])
                    copyToClipboard(MANTECA_CBU)
                    setTimeout(() => {
                      showCopiedValues([
                        ...copiedValues.map((value, index) =>
                          index === CBU_INDEX ? false : value,
                        ),
                      ])
                    }, 2500)
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={classNames(styles.property, styles.column)}>
          <div className={styles.copyRow}>
            <span>{t('deposit.pesos.alias')}</span>
            {(copiedValues[ALIAS_INDEX] && <span>{t('copied')}</span>) || (
              <>
                <span className={styles.value}>{MANTECA_ALIAS}</span>
                <Copy
                  onClick={() => {
                    showCopiedValues([...copiedValues.map((_, index) => index === ALIAS_INDEX)])
                    copyToClipboard(MANTECA_ALIAS)
                    setTimeout(() => {
                      showCopiedValues([
                        ...copiedValues.map((value, index) =>
                          index === ALIAS_INDEX ? false : value,
                        ),
                      ])
                    }, 2500)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <InfoPanel type="warning" description={t('deposit.pesos.transferAlert')} />
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          styledType="filled"
          filledColor="primary"
          onClick={() => changeMethod()}>
          {t('return')}
        </Button>
      </div>
    </>
  )
}

export default withTranslation()(Pesos)
