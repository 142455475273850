import {ReactNode} from 'react'
import {create, StateCreator} from 'zustand'

import {
  PaymentMethodKey,
  paymentMethods,
} from '../../pages/investment/steps/PaymentMethod/constant/methods'
import {PaymentMethod} from '../../services/interfaces/IToken'
import {getInvestmentStorage} from '../localStorage/investment'

export interface IPaymentMethod {
  key: PaymentMethodKey
  type: PaymentMethod
  icon: ReactNode
  iconClassName?: string
}
export interface PaymentMethodSlice {
  activePaymentMethod: IPaymentMethod | undefined
  setActivePaymentMethod: (itemId: IPaymentMethod) => void
  availablePaymentMethods: IPaymentMethod[]
  setAvailablePaymentMethods: (methods: IPaymentMethod[]) => void
}

const getPaymentMethodByType = (type: PaymentMethod | undefined) =>
  !!type ? paymentMethods.find(paymentMethod => paymentMethod.type === type) : undefined

const createPaymentMethodSlice: StateCreator<PaymentMethodSlice> = set => ({
  activePaymentMethod: getPaymentMethodByType(getInvestmentStorage()?.paymentMethodTypeSelected),
  availablePaymentMethods: [],
  setActivePaymentMethod: (activePaymentMethod: IPaymentMethod) => {
    set(() => ({activePaymentMethod}))
  },
  setAvailablePaymentMethods: (availablePaymentMethods: IPaymentMethod[]) => {
    set(() => ({availablePaymentMethods}))
  },
})

export const usePaymentMethodSlice = create<PaymentMethodSlice>()((...a) => ({
  ...createPaymentMethodSlice(...a),
}))
