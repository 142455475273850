import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Alert} from '../../assets/icons/alert.svg'
import {ReactComponent as Pesos} from '../../assets/icons/argentina.svg'
import {ReactComponent as PrevArrow} from '../../assets/icons/arrow-prev.svg'
import {ReactComponent as Failure} from '../../assets/icons/error-alert.svg'
import {ReactComponent as Refresh} from '../../assets/icons/refresh.svg'
import {ReactComponent as Success} from '../../assets/icons/success.svg'
import {ReactComponent as Usdt} from '../../assets/icons/usdt.svg'
import Button from '../../components/Button/Button'
import DynamicInput from '../../components/DynamicInput/DynamicInput'
import Modal from '../../components/Modal/Modal'
import {CURRENCY} from '../../constants/currencies'
import {PATHS} from '../../constants/paths'
import {REG_EX} from '../../constants/regExpression'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWeb3} from '../../contexts/Web3Context'
import useBreakpoint from '../../hooks/useBreakpoint'
import {mantecaApi} from '../../services'
import {useIdentityValidationSlice} from '../../store/slices/identityValidation'
import {useRatesSlice} from '../../store/slices/rates'
import Config from '../../utils/config'
import {
  formatNumberToString,
  formatStringToNumber,
  getDecimalSeparator,
} from '../../utils/formatNumber'
import {foundRate} from '../../utils/rates'

import styles from './Swap.module.scss'

interface SwapForm {
  fromAmount: string
  toAmount: string
}

const Swap = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {rates, fetchRates} = useRatesSlice()
  const [rate, setRate] = useState<number>()
  const {web3} = useWeb3()
  const {
    user,
    balance,
    pesosBalance,
    fetchingBalance,
    fetchingPesosBalance,
    getBalance,
    getPesosBalance,
    getPesosAccounts,
    pesosBankAccounts,
    fetchingAccounts,
  } = useUser()
  const {isUserValidated, checkIdentityValidation} = useIdentityValidationSlice()
  const [swapping, setSwap] = useState<boolean>(false)
  const [modalVisible, showModal] = useState<boolean>(false)
  const [successfulSwap, setSuccess] = useState<boolean>(false)
  const [fromCurrencyChanged, changeFromCurrency] = useState<boolean>(true)
  const [fromCurrency, setFromCurrency] = useState<'ARS' | 'USDT'>('ARS')
  const {
    control,
    formState: {errors, isValid},
    setValue,
    watch,
    handleSubmit,
    trigger,
  } = useForm<SwapForm>({
    defaultValues: {
      fromAmount: '0',
      toAmount: '0',
    },
  })
  const [fromAmount, toAmount] = watch(['fromAmount', 'toAmount'])
  const breakpoint = useBreakpoint()
  const [accountModalVisible, showAccountsModal] = useState<boolean>(
    !fetchingAccounts && !pesosBankAccounts?.length,
  )

  const toCurrency = useMemo<'ARS' | 'USDT'>(
    () => (fromCurrency === 'ARS' && 'USDT') || 'ARS',
    [fromCurrency],
  )
  const fromAvailable = useMemo<string>(
    () => (fromCurrency === 'ARS' && pesosBalance) || balance,
    [fromCurrency, pesosBalance, balance],
  )
  const toAvailable = useMemo<string>(
    () => (toCurrency === 'ARS' && pesosBalance) || balance,
    [toCurrency, pesosBalance, balance],
  )

  const onSwap = useCallback(
    async ({fromAmount: fromAmountInput, toAmount: toAmountInput}: SwapForm) => {
      try {
        setSwap(true)
        if (formatStringToNumber(fromAmountInput) > formatStringToNumber(fromAvailable)) return
        const onSwapResponse = await mantecaApi.swap(
          (fromCurrency === 'ARS' && formatStringToNumber(toAmountInput)) ||
            formatStringToNumber(fromAmountInput),
          (fromCurrency === 'ARS' && 'BUY') || 'SELL',
        )
        console.log('onSwapResponse', onSwapResponse)
        setSuccess(onSwapResponse.status.toUpperCase() === 'COMPLETED')
        showModal(true)
      } catch (error) {
        setSuccess(false)
        showModal(true)
      } finally {
        setSwap(false)
      }
    },
    [
      toAvailable,
      fromAvailable,
      fromCurrency,
      toCurrency,
      swapping,
      successfulSwap,
      modalVisible,
      errors,
    ],
  )

  const onChangeAmount = useCallback(
    (amountValue: string, origin: 'fromAmount' | 'toAmount') => {
      const thousandSeparator = (getDecimalSeparator() === '.' && ',') || '.'
      const amountValueFormatted = amountValue.replaceAll(thousandSeparator, '')
      if (!REG_EX.DECIMAL.test(amountValueFormatted)) return
      if (
        amountValue.endsWith(getDecimalSeparator()) ||
        (amountValue.includes(getDecimalSeparator()) && amountValue.endsWith('0'))
      ) {
        setValue(origin, amountValue)
        return
      }
      const originAmount = formatStringToNumber(amountValueFormatted)
      const currency = (origin === 'fromAmount' && fromCurrency) || toCurrency
      if (!rate || originAmount === 0) {
        setValue('toAmount', '0')
        setValue('fromAmount', '0')
        return
      }
      const destinationAmount = currency === 'ARS' ? originAmount / rate : rate * originAmount
      setValue(origin, formatNumberToString(originAmount))
      setValue(
        (origin === 'fromAmount' && 'toAmount') || 'fromAmount',
        formatNumberToString(destinationAmount),
      )
    },
    [rate, fromCurrency, toCurrency, fromAmount, toAmount],
  )

  const fetchBalances = useCallback(() => {
    if (!user) return
    getBalance()
    getPesosBalance()
    fetchRates()
  }, [user, web3])

  useEffect(() => {
    if ((Config.isMantecaEnabled && isUserValidated) || !Config.isMantecaEnabled) return
    checkIdentityValidation()
  }, [])

  useEffect(() => {
    fetchBalances()
  }, [])

  useEffect(() => {
    if (!rates?.length) return
    const rateFounded = foundRate(rates, 'USDT', 'ARS')
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates])

  useEffect(() => {
    if (fromAmount !== '0' && toAmount !== '0') trigger()
  }, [fromAmount, toAmount])

  useEffect(() => {
    getPesosAccounts()
  }, [])

  useEffect(() => {
    if (!fetchingAccounts) showAccountsModal(!fetchingAccounts && !pesosBankAccounts?.length)
  }, [fetchingAccounts])

  if (!Config.isMantecaEnabled) {
    navigate(-1)
    return null
  }

  return (
    <>
      <BannerSection className={styles.section}>
        <BannerContainer className={styles.container} layout="column">
          <div className={styles.header}>
            <PrevArrow className={styles.prev} onClick={() => navigate(PATHS.PORTFOLIO)} />
            <span>{t('swap.title')}</span>
          </div>
          <form className={styles.body} onSubmit={handleSubmit(onSwap)}>
            <div className={styles.from}>
              <span>{t('swap.wantToChange')}</span>
              <div className={styles.row}>
                <span className={styles.left}>
                  {(fromCurrency === 'ARS' && <Pesos />) || <Usdt />} {fromCurrency}
                </span>
                <div className={styles.right}>
                  <Controller
                    control={control}
                    name="fromAmount"
                    rules={{
                      required: true,
                      validate: value => {
                        if (!!value) {
                          const valueFormated = formatStringToNumber(value)
                          return (
                            valueFormated > 0 &&
                            valueFormated <= formatStringToNumber(fromAvailable)
                          )
                        }
                        return true
                      },
                    }}
                    render={({field: {name, ...rest}}) => (
                      <div
                        className={classNames(styles.inputContainer, {
                          [styles.error]: !!errors[name],
                        })}>
                        <DynamicInput
                          type="string"
                          {...rest}
                          minimalFontSize={(breakpoint !== 's' && breakpoint !== 'xs' && 24) || 20}
                          initialFontSize={(breakpoint !== 's' && breakpoint !== 'xs' && 40) || 36}
                          maxPercentageWidth={100}
                          onChange={e => {
                            onChangeAmount(e.target.value, 'fromAmount')
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <span className={styles.available}>
                  {t('swap.available')}:{' '}
                  {(fromCurrency === 'ARS' && fetchingPesosBalance) || fetchingBalance ? (
                    <Skeleton width={70} height={15} />
                  ) : (
                    <span>
                      {fromAvailable} {fromCurrency}
                    </span>
                  )}
                </span>
                <span
                  className={styles.max}
                  onClick={() => onChangeAmount(fromAvailable, 'fromAmount')}>
                  Max
                </span>
              </div>
            </div>
            <div className={styles.lines}>
              <span />
              <span
                className={classNames(styles.circle, {[styles.clicked]: fromCurrencyChanged})}
                onClick={() => {
                  changeFromCurrency(true)
                  setTimeout(() => changeFromCurrency(false), 1000)
                  setFromCurrency((fromCurrency === 'ARS' && 'USDT') || 'ARS')
                  setValue('fromAmount', '0')
                  setValue('toAmount', '0')
                }}>
                <Refresh />
              </span>
              <span />
            </div>
            <div className={styles.to}>
              <span>{t('swap.wantToReceive')}</span>
              <div className={styles.row}>
                <span className={styles.left}>
                  {(toCurrency === 'ARS' && <Pesos />) || <Usdt />} {toCurrency}
                </span>
                <div className={styles.right}>
                  <Controller
                    control={control}
                    name="toAmount"
                    rules={{
                      required: true,
                    }}
                    render={({field: {name, ...rest}}) => (
                      <div
                        className={classNames(styles.inputContainer, {
                          [styles.error]: !!errors[name],
                        })}>
                        <DynamicInput
                          type="string"
                          {...rest}
                          minimalFontSize={(breakpoint !== 's' && breakpoint !== 'xs' && 24) || 20}
                          initialFontSize={(breakpoint !== 's' && breakpoint !== 'xs' && 40) || 36}
                          maxPercentageWidth={100}
                          onChange={e => {
                            onChangeAmount(e.target.value, 'toAmount')
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className={styles.row} style={{paddingBottom: '1rem'}}>
                <span className={styles.available}>
                  {t('swap.available')}:{' '}
                  {(toCurrency === 'ARS' && fetchingPesosBalance) || fetchingBalance ? (
                    <Skeleton width={70} height={15} />
                  ) : (
                    <span>
                      {toAvailable} {toCurrency}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <span className={styles.line} />
            <div className={classNames(styles.row, styles.fee)}>
              <span>{t('swap.exchangeRate')}</span>
              <span>
                {`1 ${CURRENCY.USDT}`} = {`${formatNumberToString(rate || 0)} ${CURRENCY.ARS}`}
              </span>
            </div>
            <span className={styles.line} />
            <span
              className={classNames(styles.errorMsg, {
                [styles.visible]:
                  !!errors['fromAmount'] &&
                  formatStringToNumber(fromAmount) > formatStringToNumber(fromAvailable),
              })}>
              {t('swap.insufficientBalance', {
                currency: `${fromCurrency}`,
              })}
            </span>
            <span className={styles.footer}>
              {t('swap.byClickingOn')} <span>{`"${t('acceptAndConvert')}"`}</span>{' '}
              {t('swap.yoAgreeTo')}{' '}
              <a
                className={styles.link}
                href="https://bafybeihgiynbsg3mgyahyvm3bkaomnpgnkm476p4plfw6npe6ww5ofuz2i.ipfs.dweb.link/"
                target="_blank"
                rel="noopener noreferrer">
                {t('termsAndConditions')} {t('and')} {t('privacyPolicies')}
              </a>{' '}
              {t('swap.ofThePlatform')}
            </span>

            <Button filledColor="primary" type="submit" disabled={!isValid} loading={swapping}>
              {t('acceptAndConvert')}
            </Button>
          </form>
        </BannerContainer>
      </BannerSection>

      <Modal
        visible={modalVisible}
        onClose={() => {
          setValue('fromAmount', '0')
          setValue('toAmount', '0')
          showModal(false)
        }}
        closeButtonHidden
        outerClassName={styles.modal}
        icon={
          <div className={(successfulSwap && styles.success) || styles.failure}>
            {(successfulSwap && <Success />) || <Failure />}
            <span>{t(`swap.modal.${(successfulSwap && 'successfullSwap') || 'failedSwap'}`)}</span>
          </div>
        }
        title={t(`swap.modal.${(successfulSwap && 'swapFromTo') || 'problems'}`, {
          from: `${fromCurrency}`,
          to: `${toCurrency}`,
        })}
        button={{
          label: t('close'),
          onClick: () => {
            navigate(PATHS.PORTFOLIO)
          },
        }}
      />

      <Modal
        visible={accountModalVisible}
        onClose={() => showAccountsModal(false)}
        closeButtonHidden
        outerClassName={styles.accountModal}
        containerClassName={styles.containerModal}
        icon={<Alert />}
        title={t('bankAccount.modal.addAccount')}
        subtitle={t('bankAccount.modal.message')}
        button={{
          label: t('continue'),
          onClick: () => {
            navigate(PATHS.ADD_BANK_ACCOUNT, {state: {pathToReturn: PATHS.SWAP}})
          },
        }}
      />
    </>
  )
}

export default withTranslation()(Swap)
