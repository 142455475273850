import DestinationAddress from './DestinationAddress'
import FundAmount from './FundAmount'
import WithdrawalConfirmation from './WithdrawalConfirmation'

const withdrawalUsdtSteps = [
  <FundAmount key={0} />,
  <DestinationAddress key={1} />,
  <WithdrawalConfirmation key={2} />,
]

export default withdrawalUsdtSteps
