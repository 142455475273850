import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import ActionList from '../../components/ActionList/ActionList'
import FeaturedItems from '../../components/FeaturedItems/FeaturedItems'
import ItemList from '../../components/ItemList/ItemList'
// import Search from '../../components/Search/Search'
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner'

const Home = () => {
  const {hash} = useLocation()

  useEffect(() => {
    if (hash)
      setTimeout(() => {
        window.scrollTo({
          top: (document.getElementById(hash.replace('#', ''))?.offsetTop || 0) - 100,
          behavior: 'smooth',
        })
      }, 1000)
  }, [hash])

  return (
    <>
      <WelcomeBanner />
      {/* <Search /> */}
      <FeaturedItems />
      <ItemList />
      <ActionList />
    </>
  )
}

export default Home
