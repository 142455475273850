import {useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../../components/Button/Button'
import {mantecaApi} from '../../../../../services'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'

import styles from './styles.module.scss'
const CheckBankAccount = ({t}: WithTranslation) => {
  const {
    activeStepIndex,
    nextStep,
    updateWithdrawalData,
    withdrawalData,
    resetWithdrawal,
    lastStep,
  } = useWithdrawalSlice()
  const [deleting, setDeleting] = useState<boolean>(false)

  const onCorrect = async () => {
    try {
      if (!withdrawalData?.bankAccountChosen) return
      setDeleting(true)
      await mantecaApi.deleteBankAccount(withdrawalData.bankAccountChosen.cbu)
      updateWithdrawalData({bankAccountChosen: null})
      lastStep()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setDeleting(false)
    }
  }

  return (
    <>
      <div className={styles.header}>
        <PrevArrow
          className={styles.prev}
          onClick={() => (activeStepIndex === 0 && resetWithdrawal()) || lastStep()}
        />
        <span>{t('bankAccount.steps.check.subtitle')}</span>
      </div>
      <section className={styles.form}>
        {!!withdrawalData?.bankAccountChosen && (
          <div className={styles.rowsData}>
            <div className={styles.row}>
              <span>{t('bankAccount.currency')}</span>
              <span style={{fontWeight: 400}}>ARS</span>
            </div>
            <div className={styles.row}>
              <span>CBU</span>
              <span>{withdrawalData.bankAccountChosen?.cbu || '012345678910111213141516'}</span>
            </div>
            <div className={styles.row}>
              <span>{t('bankAccount.bank')}</span>
              <span>{withdrawalData.bankAccountChosen?.bankName || 'Santander Rio'}</span>
            </div>
            <div className={styles.row}>
              <span>{t('bankAccount.accountName')}</span>
              <span>{withdrawalData.bankAccountChosen?.description || 'nombre'}</span>
            </div>
          </div>
        )}
        <Button
          className={styles.button}
          styledType="filled"
          filledColor="gray"
          loading={deleting}
          onClick={onCorrect}>
          {t('correctData')}
        </Button>
        <Button
          style={{marginTop: 0}}
          className={styles.button}
          styledType="filled"
          filledColor="primary"
          onClick={nextStep}>
          {t('confirm')}
        </Button>
      </section>
    </>
  )
}

export default withTranslation()(CheckBankAccount)
