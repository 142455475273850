import {ReactNode, useEffect, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as PesosIcon} from '../../assets/icons/argentina.svg'
import {ReactComponent as PrevArrow} from '../../assets/icons/arrow-prev.svg'
import {ReactComponent as Arrow} from '../../assets/icons/arrow-up.svg'
import {ReactComponent as UsdtIcon} from '../../assets/icons/usdt.svg'
import {CURRENCY} from '../../constants/currencies'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {COUNTRY} from '../../services/interfaces/IAddress'
import {VALIDATION_STATUS} from '../../services/interfaces/ICustomerInfo'
import {useCustomerSlice} from '../../store/slices/customer'
import {useIdentityValidationSlice} from '../../store/slices/identityValidation'
import {useWithdrawalSlice} from '../../store/slices/withdrawal'
import Config from '../../utils/config'
import InProgressValidationModal from '../identityValidation/InProgressValidation/InProgressValidationModal'

import Pesos from './methods/pesos/Pesos'
import Usdt from './methods/usdt/Usdt'

import styles from './Withdrawal.module.scss'

export type WithdrawalMethod = 'pesos' | 'usdt'
export interface IWithdrawalMethod {
  className?: string
  type: WithdrawalMethod
  label: string
  description: string | null
  icon: ReactNode
  iconClassName?: string
  disabled?: boolean
}
const Withdrawal = ({t}: WithTranslation) => {
  const {getPesosAccounts, pesosBankAccounts} = useUser()
  const navigate = useNavigate()
  const {
    customer_info: {manteca_user, address},
    refreshCustomer,
    isFetching,
  } = useCustomerSlice()
  const {checkIdentityValidation, isUserValidated, validating} = useIdentityValidationSlice()
  const {
    initSteps,
    type: withdrawalMethod,
    setWithdrawalMethod,
    lastStep,
    initPesosIndex,
  } = useWithdrawalSlice()
  const [inProgressModalVisible, showModalInProgressValidation] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const withdrawalMethods = useMemo<IWithdrawalMethod[]>(
    () =>
      [
        {
          type: 'pesos',
          label: CURRENCY.ARS,
          description: t('pesos'),
          icon: <PesosIcon className={styles.pesosIcon} />,
          className: styles.pesos,
          disabled: !Config.isMantecaEnabled,
        },
        {
          type: 'usdt',
          label: CURRENCY.USDT,
          description: t('usdt'),
          icon: <UsdtIcon className={styles.usdtIcon} />,
          className: styles.usdt,
        },
      ].filter(m => !m.disabled) as IWithdrawalMethod[],
    [t],
  )

  useEffect(() => {
    if ((Config.isMantecaEnabled && isUserValidated) || !Config.isMantecaEnabled) return
    refreshCustomer()
    setTimeout(() => checkIdentityValidation(), 1000)
    const interval = setInterval(checkIdentityValidation, 10000)
    return () => clearInterval(interval)
  }, [checkIdentityValidation, refreshCustomer])

  useEffect(() => {
    if (!!withdrawalMethod) {
      initSteps(withdrawalMethod)
      if (withdrawalMethod === 'pesos' && !!pesosBankAccounts.length) {
        initPesosIndex(!!pesosBankAccounts.length)
      }
    }
  }, [withdrawalMethod, pesosBankAccounts.length])

  useEffect(() => {
    getPesosAccounts()
  }, [])

  useEffect(() => {
    if (loading) setLoading(!!validating || !!isFetching)
  }, [validating, isFetching, isUserValidated])

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        {!withdrawalMethod && (
          <>
            <div className={styles.header}>
              <PrevArrow
                className={styles.prev}
                onClick={() => (!!withdrawalMethod && lastStep()) || navigate(PATHS.PORTFOLIO)}
              />
              <span>{t('withdrawal.title')}</span>
              <span>{t('withdrawal.subtitle')}</span>
            </div>
            {!!withdrawalMethods?.length &&
              withdrawalMethods.map((withdrawalMethod: IWithdrawalMethod, index: number) => (
                <div key={index} className={styles.withdrawalMethodContainer}>
                  <div
                    className={classNames(styles.withdrawalMethod, withdrawalMethod.className, {
                      [styles.disable]: loading,
                    })}
                    style={
                      !loading &&
                      withdrawalMethod.type === 'pesos' &&
                      address?.country?.toUpperCase() !== COUNTRY.ARGENTINA
                        ? {display: 'none'}
                        : {}
                    }
                    onClick={() => {
                      if (withdrawalMethod.type === 'pesos' && !isUserValidated) {
                        if (
                          !!manteca_user?.validation_status &&
                          (manteca_user.validation_status.toUpperCase() ===
                            VALIDATION_STATUS.IN_PROGRESS ||
                            manteca_user.validation_status.toUpperCase() ===
                              VALIDATION_STATUS.NOT_INITIATED)
                        ) {
                          showModalInProgressValidation(true)
                          return
                        }
                        navigate(PATHS.IDENTITY_VALIDATION, {
                          state: {pathOnValidationFinished: PATHS.WITHDRAWAL},
                        })
                        return
                      }
                      setWithdrawalMethod(withdrawalMethod.type)
                    }}>
                    <div className={styles.box}>
                      <div className={classNames(styles.circle, withdrawalMethod.iconClassName)}>
                        {(loading && <Skeleton />) || withdrawalMethod.icon}
                      </div>
                      <div className={classNames(styles.textContainer, withdrawalMethod.className)}>
                        <span className={styles.name}>
                          {(loading && <Skeleton width={120} />) || withdrawalMethod.label}
                        </span>
                        <span className={styles.description}>
                          {(loading && <Skeleton width={200} />) || withdrawalMethod.description}
                        </span>
                      </div>
                      <div className={styles.right}>
                        <Arrow className={styles.arrow} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
        {!!withdrawalMethod &&
          ((withdrawalMethod === 'pesos' && <Pesos />) ||
            (withdrawalMethod === 'usdt' && <Usdt />))}

        <InProgressValidationModal
          visible={inProgressModalVisible}
          hide={() => showModalInProgressValidation(false)}
        />
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Withdrawal)
