import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import Button from '../../../../../components/Button/Button'

interface IBankAccountForm {
  cbu_alias: string
  name: string
}
import {useState} from 'react'
import {FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'

import TextInput from '../../../../../components/TextInput/TextInput'
import {useUser} from '../../../../../contexts/UserContext'
import {mantecaApi} from '../../../../../services'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'

import {WITHDRAWAL_PESOS_STEPS} from './steps'

import styles from './styles.module.scss'
const AddBankAccount = ({t}: WithTranslation) => {
  const {fetchingAccounts, pesosBankAccounts} = useUser()
  const {
    activeStepIndex,
    goToStep,
    updateWithdrawalData,
    withdrawalData,
    resetWithdrawal,
    lastStep,
  } = useWithdrawalSlice()
  const methods = useForm<IBankAccountForm>({
    defaultValues: {
      cbu_alias: withdrawalData?.cbu_alias || '',
      name: withdrawalData?.name || '',
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onSubmit = async (data: IBankAccountForm) => {
    try {
      setSubmitting(true)
      const bankAccountChosen = await mantecaApi.addBankAccount(data.cbu_alias, data.name)
      updateWithdrawalData({
        cbu_alias: data.cbu_alias,
        name: data.name,
        bankAccountChosen,
      })
      goToStep(
        (!!pesosBankAccounts.length && WITHDRAWAL_PESOS_STEPS.CHOOSE_ACCOUNT) ||
          WITHDRAWAL_PESOS_STEPS.CHECK_ACCOUNT,
      )
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IBankAccountForm> | FieldErrors<IBankAccountForm>,
  ) => {
    console.log('ErrorData', errorData)
  }

  return (
    <>
      {!fetchingAccounts && (
        <>
          <div className={styles.header}>
            <PrevArrow
              className={styles.prev}
              onClick={() => (activeStepIndex === 0 && resetWithdrawal()) || lastStep()}
            />
            <span>{t('bankAccount.steps.add.subtitle')}</span>
          </div>
          <FormProvider {...methods}>
            <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
              <TextInput
                label={t('bankAccount.cbu_alias')}
                name="cbu_alias"
                placeholder={t('bankAccount.cbu_aliasPlaceholder')}
                withUseForm
                underlined
                required
              />
              <TextInput
                label={t('bankAccount.name')}
                name="name"
                placeholder={t('bankAccount.namePlaceholder')}
                withUseForm
                underlined
                required
              />
              <Button
                className={styles.button}
                styledType="filled"
                filledColor="primary"
                type="submit"
                loading={submitting}>
                {t('continue')}
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </>
  )
}

export default withTranslation()(AddBankAccount)
