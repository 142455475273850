import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Time} from '../../../assets/icons/time.svg'
import Modal from '../../../components/Modal/Modal'

import styles from '../IdentityValidation.module.scss'

interface IInProgressValidationModal extends WithTranslation {
  visible: boolean
  hide: () => void
}

const InProgressValidationModal = ({visible, hide, t}: IInProgressValidationModal) => (
  <Modal
    visible={visible}
    onClose={hide}
    closeButtonHidden
    outerClassName={styles.modal}
    containerClassName={styles.containerModal}
    icon={
      <div className={styles.pending}>
        <Time />
        <span>{t('pending')}</span>
      </div>
    }
    title={t('identityValidation.modalInProgress.title')}
    subtitle={t('identityValidation.modalInProgress.message')}
    button={{
      label: t('continue'),
      onClick: () => hide(),
    }}>
    <span className={styles.description}>
      {t('identityValidation.modalInProgress.contactData', {
        contact_phone: `${6456516}`,
        email: `${'asdsda@dasd'}`,
      })}
    </span>
  </Modal>
)

export default withTranslation()(InProgressValidationModal)
