import {useCallback, useEffect, useMemo} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as ArrowPrev} from '../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-up.svg'
import InfoPanel from '../../../../components/InfoPanel/InfoPanel'
import {PATHS} from '../../../../constants/paths'
import {PAYMENT_METHOD} from '../../../../services/interfaces/IToken'
import {saveInvestmentStorage} from '../../../../store/localStorage/investment'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePaymentMethodSlice} from '../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../store/slices/project'
import {useStepperSlice} from '../../../../store/slices/stepper'
import Config from '../../../../utils/config'

import {IPaymentMethod, paymentMethods} from './constant/methods'

import styles from './PaymentMethod.module.scss'

const PaymentMethod = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {setActivePaymentMethod, setAvailablePaymentMethods, availablePaymentMethods} =
    usePaymentMethodSlice()
  const {nextStep} = useStepperSlice()
  const {
    customer_info: {identity_verification_status},
    refreshCustomer,
  } = useCustomerSlice()
  const {
    id,
    tokenomic: {token},
  } = useProjectSlice()

  const isKycPending = useMemo<boolean>(
    () =>
      !!(
        Config.isKycEnabled &&
        identity_verification_status &&
        identity_verification_status !== 'approved'
      ),
    [Config, identity_verification_status],
  )

  const selectPaymentMethod = useCallback(
    (paymentMethod: IPaymentMethod) => {
      if (paymentMethod.comingSoon) return
      if (!isKycPending) {
        setActivePaymentMethod({
          key: paymentMethod.key,
          type: paymentMethod.type,
          icon: paymentMethods.find(method => method.type === paymentMethod.type)?.icon,
          iconClassName: paymentMethod.iconClassName,
        })
        saveInvestmentStorage({
          paymentMethodTypeSelected: paymentMethod.type,
        })
        nextStep()
      }
    },
    [
      isKycPending,
      availablePaymentMethods,
      setActivePaymentMethod,
      saveInvestmentStorage,
      nextStep,
    ],
  )

  useEffect(() => {
    if (!isKycPending) return
    setTimeout(() => refreshCustomer(), 1000)
    const interval = setInterval(refreshCustomer, 30000)
    return () => clearInterval(interval)
  }, [refreshCustomer])

  useEffect(() => {
    if (!!id && !!token)
      setAvailablePaymentMethods(
        (!!token?.payment_methods?.length &&
          paymentMethods.filter(paymentMethod =>
            token.payment_methods.some(payment_method => payment_method === paymentMethod.type),
          )) ||
          paymentMethods,
      )
  }, [token, id, t, paymentMethods])

  useEffect(() => {
    if (availablePaymentMethods?.length === 1) selectPaymentMethod(availablePaymentMethods[0])
  }, [availablePaymentMethods])
  return (
    <div className={styles.container}>
      {isKycPending && (
        <InfoPanel
          className={styles.warning}
          label="Estamos validando tu identidad"
          description="Una vez que este confirmada tu validación de identidad podrás continuar con el proceso"
          type="warning"
        />
      )}
      <div className={styles.title}>
        <ArrowPrev className={styles.return} onClick={() => navigate(-1)} />
        <span>{t('investment.paymentMethod.selectPaymentMethod')}</span>
      </div>
      {!!availablePaymentMethods?.length &&
        availablePaymentMethods.map((paymentMethod: IPaymentMethod, index: number) => (
          <div key={index} className={styles.paymentMethodContainer}>
            {index + 1 === availablePaymentMethods?.length &&
              availablePaymentMethods?.length > 1 &&
              paymentMethod.type === PAYMENT_METHOD.wallet && (
                <span className={styles.or}>{t('investment.paymentMethod.orAccessWith')}</span>
              )}
            {paymentMethod.type === PAYMENT_METHOD.wallet && (
              <InfoPanel
                type="info"
                label={t('investment.paymentMethod.fundInfo')}
                description={
                  <Trans i18nKey="investment.paymentMethod.fundInfoMessage">
                    Si todavía no fondeaste tu billetera,
                    <span
                      className={styles.link}
                      onClick={() => navigate(PATHS.DEPOSIT, {state: {projectId: id}})}>
                      clickeá acá
                    </span>
                  </Trans>
                }
              />
            )}
            <div
              onClick={() => selectPaymentMethod(paymentMethod)}
              className={classNames(styles.paymentMethod, paymentMethod.className, {
                [styles.disable]: isKycPending,
                [styles.comingSoon]: !!paymentMethod.comingSoon,
              })}>
              <div className={styles.box}>
                <div className={classNames(styles.circle, paymentMethod.iconClassName)}>
                  {paymentMethod.icon}
                </div>
                <div className={classNames(styles.textContainer, paymentMethod.className)}>
                  <span className={styles.name}>
                    {t(`investment.paymentMethod.${paymentMethod.key}`)}
                  </span>
                  {!!paymentMethod.descriptionKey && (
                    <span
                      className={classNames(styles.description, {[styles.disable]: isKycPending})}>
                      {(!!paymentMethod.comingSoon && 'COMING SOON') ||
                        t(paymentMethod.descriptionKey)}
                    </span>
                  )}
                  {!!paymentMethod.descriptionIcons && (
                    <span className={styles.description}>{paymentMethod.descriptionIcons}</span>
                  )}
                </div>
                <div className={styles.right}>
                  {!!paymentMethod.walletIcon && (
                    <div className={styles.wallet}>
                      <span>Secured by</span>
                      {paymentMethod.walletIcon}
                    </div>
                  )}
                  <Arrow className={styles.arrow} />
                </div>
              </div>
            </div>
            {!!paymentMethod.linkDescriptionKey && (
              <div className={styles.linkDescription}>
                {t(paymentMethod.linkDescriptionKey)}
                {!!paymentMethod.linkLabelKey && !!paymentMethod.link && (
                  <a href={paymentMethod.link} target="_blank" rel="noopener noreferrer">
                    {t(paymentMethod.linkLabelKey)}
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default withTranslation()(PaymentMethod)
