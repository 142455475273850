import React from 'react'
import ReactDOM from 'react-dom/client'

import './utils/i18n'

import App from './App'

import './styles/mainStyles.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
