import {CURRENCY, CurrencyType} from '../../constants/currencies'
import axios from '../../core/axios'
import {
  Balance,
  BankAccount,
  BankAccountType,
  ExchangeOperation,
  IMantecaExchange,
  IMantecaWithdraw,
  MantecaWithdrawStatus,
} from '../interfaces/IMantecaWithdraw'

export default {
  getPesosBalance: async (): Promise<string> => {
    const {data} = await axios.get('manteca/users')
    return (!!data?.balance && (data.balance as Balance).fiat?.ARS?.amount) || '0'
  },
  getUsdtBalance: async (): Promise<string> => {
    const {data} = await axios.get('manteca/users')
    return (!!data?.balance && (data.balance as Balance).crypto?.USDT?.amount) || '0'
  },
  getPesosAccounts: async (): Promise<BankAccount[]> => {
    const {data} = await axios.get('manteca/users')
    return (
      (!!data?.bankAccounts &&
        (data.bankAccounts as Record<BankAccountType, BankAccount[]>)?.ARS) ||
      []
    )
  },
  isUserValidated: async (): Promise<boolean> => {
    const {
      data: {isValidated},
    } = await axios.get('manteca/validation')
    return isValidated
  },
  createUser: (legal_id: string, civil_state: string) =>
    axios.post('manteca/create_user/', {legal_id, civil_state}),
  uploadFile: async (file: FormData, type: 'dni_front' | 'dni_back' | 'funds') =>
    axios.post(`manteca/upload/${type}/`, file),
  finishValidation: () => axios.post('manteca/finish_validation/'),
  addBankAccount: async (cbu: string, description: string): Promise<BankAccount> => {
    const {data} = await axios.post('manteca/add_bank_account/', {cbu, description})
    return data
  },
  deleteBankAccount: async (cbu: string) =>
    await axios.delete(`manteca/delete_bank_account/ARS/${cbu}/`),
  withdraw: async (
    amount: number,
    account_index: number,
    coin = CURRENCY.ARS,
  ): Promise<MantecaWithdrawStatus> => {
    const {data} = await axios.post('manteca/withdraw/', {
      coin,
      amount,
      account_index,
    })
    return (data as IMantecaWithdraw[])[0]?.status || (data as IMantecaWithdraw).status
  },
  swap: async (
    amount: number,
    operation: ExchangeOperation,
    crypto: CurrencyType = 'USDT',
    fiat: CurrencyType = 'ARS',
  ): Promise<IMantecaExchange> => {
    console.log('body to Swap:', {
      coin: `${crypto}_${fiat}`,
      amount,
      operation,
    })
    const {data} = await axios.post('manteca/exchange/', {
      coin: `${crypto}_${fiat}`,
      amount,
      operation,
    })
    return data as IMantecaExchange
  },
}
