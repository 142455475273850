import {ReactNode, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-up.svg'
import {ReactComponent as Materials} from '../../../../../../assets/icons/materials.svg'
import {ReactComponent as Services} from '../../../../../../assets/icons/services.svg'
import {EnchangeType} from '../../../../../../services/api/permutoken'
import {useExchangeSlice} from '../../../../../../store/slices/exchange'
import {useStepperSlice} from '../../../../../../store/slices/stepper'

import styles from './styles.module.scss'

interface IChooseExchange extends WithTranslation {
  className?: string
}
interface IFormOption {
  key: EnchangeType
  label: string
  icon: ReactNode
}

const ChooseExchange = ({className, t}: IChooseExchange) => {
  const {setExchange} = useExchangeSlice()
  const {nextStep} = useStepperSlice()
  const formOptions: IFormOption[] = useMemo<IFormOption[]>(
    () => [
      {
        key: 'SERVICES',
        label: t('exchange.services'),
        icon: <Materials className={styles.icon} />,
      },
      {
        key: 'MATERIALS',
        label: t('exchange.materials'),
        icon: <Services className={styles.icon} />,
      },
    ],
    [t],
  )

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{t('exchange.exchangeQuestion')}</span>
      </div>
      {formOptions.map(formOption => (
        <div
          key={formOption.key}
          className={styles.formOptionContainer}
          onClick={() => {
            setExchange({exchange_type: formOption.key})
            nextStep()
          }}>
          <div className={styles.formOption}>
            <div className={styles.box}>
              <div className={classNames(styles.circle)}>{formOption.icon}</div>
              <div className={classNames(styles.textContainer)}>
                <span>{formOption.label}</span>
              </div>
              <Arrow className={styles.arrow} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default withTranslation()(ChooseExchange)
