import {useCallback, useEffect, useMemo, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import Button from '../../../../../../components/Button/Button'
import Checkbox from '../../../../../../components/Checkbox/Checkbox'
import Dropdown, {IValue} from '../../../../../../components/Dropdown/Dropdown'
import TextInput from '../../../../../../components/TextInput/TextInput'
import UploadFile from '../../../../../../components/UploadFile/UploadFile'
import {CURRENCY} from '../../../../../../constants/currencies'
import {REG_EX} from '../../../../../../constants/regExpression'
import {projectApi, saleApi} from '../../../../../../services'
import {IToken} from '../../../../../../services/interfaces/IToken'
import {useExchangeSlice} from '../../../../../../store/slices/exchange'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'

import styles from './styles.module.scss'

interface IAgreementDetailWithProvider extends WithTranslation {
  className?: string
}
interface IForm {
  token: number | string
  comments: string
  totalQuote: string
  contractAttachment: string
  vesting: boolean
  numberOfDays?: string
}

const AgreementDetailWithProvider = ({className, t}: IAgreementDetailWithProvider) => {
  const {setExchange} = useExchangeSlice()
  const {nextStep} = useStepperSlice()
  const methods = useForm<IForm>({
    defaultValues: {
      token: '',
      comments: '',
      totalQuote: '',
      contractAttachment: '',
      vesting: false,
      numberOfDays: '',
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [tokens, setTokens] = useState<IToken[]>()
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const [vesting, token] = methods.watch(['vesting', 'token'])

  const onSubmit = async (data: IForm) => {
    try {
      setSubmitting(true)
      setExchange({
        token_id: +data.token,
        token_price: +(tokens?.find(token => +data.token === token.id)?.price || 0),
        comments: data.comments,
        total_paid: +data.totalQuote,
        file_url: data.contractAttachment,
        vesting: data.vesting,
        total_days: (!!data.vesting && data.numberOfDays && +data.numberOfDays) || undefined,
      })
      nextStep()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (errorData: SubmitErrorHandler<IForm> | FieldErrors<IForm>) => {
    console.log('PIForm-ErrorData', errorData)
  }

  const onFileUploaded = useCallback(
    async (file: FormData | any): Promise<boolean> => {
      try {
        if (errorMessage) setErrorMessage(null)
        if (!file) {
          methods.setValue('contractAttachment', '')
          return false
        }
        const fileResponse = await saleApi.uploadFile(file)
        methods.setValue('contractAttachment', fileResponse)
        return true
      } catch (error) {
        console.log('Error on uploading file', error)
        setErrorMessage(t('uploadFile.errorLoadingFile'))
        return false
      }
    },
    [errorMessage, setErrorMessage, methods.setValue],
  )

  const tokenPrice: string = useMemo<string>(
    () =>
      (!!token && !!tokens && tokens.find(tokenElement => +token === tokenElement.id)?.price) || '',
    [token, tokens],
  )

  const tokenOptions: IValue[] = useMemo<IValue[]>(
    () =>
      (!!tokens &&
        tokens.map(token => ({
          key: token.id.toString(),
          label: token.name,
        }))) ||
      ([] as IValue[]),
    [t, tokens],
  )
  const getTokens = useCallback(async () => {
    const projectData = await projectApi.getProjects()
    setTokens(
      projectData
        // .filter(project => project.is_opportunity)
        .map(project => project.tokenomic.token),
    )
  }, [])

  useEffect(() => {
    if (!tokenOptions.length) getTokens()
  }, [])

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{t('exchange.AgreementDetailWithProvider')}</span>
        <span className={styles.clarification}>{t('exchange.clarification')}</span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <Controller
            control={methods.control}
            name="token"
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}, ...rest}) => (
              <div className={styles.dropdownWrapper}>
                <Dropdown
                  label={t('exchange.fields.tokenToBeExchanged')}
                  name="token"
                  className={styles.dropdown}
                  customClasses={{
                    head: styles.head,
                    arrow: styles.arrow,
                  }}
                  value={tokenOptions.find(token => value === token.key) as IValue}
                  setValue={onChange}
                  options={tokenOptions}
                  underlined
                  {...rest}
                />
                {!!tokenPrice && (
                  <span>
                    <span>{t('exchange.tokenValue')}</span>
                    <span>
                      {formatNumber(+tokenPrice)} {CURRENCY.USD}
                    </span>
                  </span>
                )}
              </div>
            )}
          />
          <TextInput label={t('exchange.fields.comments')} name="comments" withUseForm underlined />
          <TextInput
            label={t('exchange.fields.totalQuote')}
            name="totalQuote"
            pattern={REG_EX.DECIMAL}
            withUseForm
            underlined
            required
          />
          <Controller
            control={methods.control}
            name="contractAttachment"
            rules={{
              required: true,
            }}
            render={({field: {name}}) => (
              <UploadFile
                name={name}
                label={t('exchange.fields.contractBetweenParties')}
                title={t('exchange.fields.attachment')}
                allowedFormats={['pdf', 'jpg', 'png']}
                onFileUploaded={onFileUploaded}
                externalErrorMessage={errorMessage}
              />
            )}
          />
          <Checkbox
            classNameContainer={styles.checkbox}
            filledColor="primary"
            label={t('exchange.fields.vesting')}
            name="vesting"
            withUseForm
          />
          {vesting && (
            <TextInput
              label={t('exchange.fields.numberOfDays')}
              name="numberOfDays"
              pattern={REG_EX.DECIMAL}
              withUseForm
              underlined
              required={vesting}
            />
          )}

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(AgreementDetailWithProvider)
