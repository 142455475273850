import {useEffect, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import Counter from '../../../../components/Counter/Counter'
import {CURRENCY, currentCurrency} from '../../../../constants/currencies'
import {saveInvestmentStorage} from '../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../store/slices/counter'
import {useProjectSlice} from '../../../../store/slices/project'
import {useRatesSlice} from '../../../../store/slices/rates'
import {formatNumberToString} from '../../../../utils/formatNumber'
import {foundRate} from '../../../../utils/rates'

import styles from './TokenCounter.module.scss'

const TokenCounter = ({t}: WithTranslation) => {
  const {
    tokenomic: {
      token,
      variable_rate,
      fixed_annual_rate,
      capital_gain_after_sale,
      estimated_end_date,
    },
  } = useProjectSlice()
  const {rates, fetchRates} = useRatesSlice()
  const {quantity} = useCounterSlice()
  const [rate, setRate] = useState<number>()

  const investmentDuration = useMemo<number>(() => {
    if (!estimated_end_date) return 0
    const currentDate = new Date()
    const estimatedEndDate = new Date(estimated_end_date)

    const yearDifference = estimatedEndDate.getFullYear() - currentDate.getFullYear()
    const monthDifference = estimatedEndDate.getMonth() - currentDate.getMonth()

    return yearDifference * 12 + monthDifference
  }, [estimated_end_date])

  const totalPrice = useMemo<number>(
    () =>
      !!quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]
        ? +token?.price * quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]
        : +token?.price,
    [token?.price, quantity],
  )
  const interests = useMemo<number>(
    () =>
      ((totalPrice * (+(variable_rate || fixed_annual_rate || 0) / 100)) / 12) * investmentDuration,
    [totalPrice, variable_rate, fixed_annual_rate],
  )
  const capitalGain = useMemo<number>(
    () => totalPrice * (+capital_gain_after_sale * 0.01),
    [totalPrice],
  )

  useEffect(() => {
    if (!!quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]) {
      saveInvestmentStorage({amountOfTokens: quantity[`${TOKEN_AMOUNT_INDEX}${token?.id}`]})
    }
  }, [quantity])

  useEffect(() => {
    if (!!rate || !rates.length || !token) return
    const rateFounded = foundRate(rates, token.currency, CURRENCY.USDT)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates, token, rate])

  useEffect(() => {
    fetchRates()
  }, [])

  if (!token?.price && !token?.available_stock) return null

  return (
    <section className={styles.body}>
      <span className={styles.label}>{t('item.investmentCalculator')}</span>
      <div className={styles.row}>
        <span>Tokens</span>
        <div className={styles.counter}>
          <Counter
            nameIndex="tokenAmount"
            tokenId={token?.id}
            initialValue={1}
            maximumValue={token?.available_stock || 2}
          />
        </div>
      </div>
      <div className={styles.row}>
        <span>Total</span>
        <span className={styles.bold}>{`${formatNumberToString(totalPrice, 2)} ${
          token?.currency || currentCurrency
        } = ${formatNumberToString((rate && totalPrice * rate) || totalPrice, 2)} ${
          CURRENCY.USDT
        }`}</span>
      </div>
      <div className={styles.row}>
        <span>{t('item.investmentDuration')}</span>
        <span className={styles.bold}>
          {investmentDuration} {t('month', {count: investmentDuration})}
        </span>
      </div>
      <div className={styles.line} />
      <div className={styles.row}>
        <span>{t('item.interests')}</span>
        <span>
          <span className={styles.bold}>{formatNumberToString(interests, 2)}</span> {CURRENCY.USD}
        </span>
      </div>
      {!!capitalGain && (
        <div className={styles.row}>
          <span>{t('item.capitalGain')}</span>
          <span>
            <span className={styles.bold}>{formatNumberToString(capitalGain, 2)}</span>{' '}
            {CURRENCY.USD}
          </span>
        </div>
      )}
      <div className={styles.line} />
      <div className={styles.row}>
        <span className={styles.bold}>{t('item.estimatedProfitability')}</span>
        <span className={styles.primary}>
          {formatNumberToString(interests + capitalGain, 2)} {CURRENCY.USD}
        </span>
      </div>
    </section>
  )
}

export default withTranslation()(TokenCounter)
