import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as PrevArrow} from '../../assets/icons/arrow-prev.svg'
import Button from '../../components/Button/Button'

interface IBankAccountForm {
  cbu_alias: string
  name: string
}
import {useCallback, useState} from 'react'
import {FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'

import TextInput from '../../components/TextInput/TextInput'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {mantecaApi} from '../../services'
import {useAddBankAccountSlice} from '../../store/slices/addBankAccount'

import styles from './styles.module.scss'

const AddBankAccount = ({t}: WithTranslation) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const {getPesosAccounts} = useUser()
  const {bankAccountData, updateBankAccountData} = useAddBankAccountSlice()
  const methods = useForm<IBankAccountForm>({
    defaultValues: {
      cbu_alias: bankAccountData?.cbu_alias || '',
      name: bankAccountData?.name || '',
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onSubmit = useCallback(
    async (data: IBankAccountForm) => {
      try {
        setSubmitting(true)
        const bankAccountChosen = await mantecaApi.addBankAccount(data.cbu_alias, data.name)
        updateBankAccountData({
          cbu_alias: data.cbu_alias,
          name: data.name,
          bankAccountChosen,
        })
        getPesosAccounts()
        setTimeout(() => navigate(state?.pathToReturn || -1), 1000)
      } catch (error: any) {
        console.log('Error on put data', error)
      } finally {
        setSubmitting(false)
      }
    },
    [submitting, setSubmitting, updateBankAccountData, state, getPesosAccounts],
  )
  const onSubmitError = (
    errorData: SubmitErrorHandler<IBankAccountForm> | FieldErrors<IBankAccountForm>,
  ) => {
    console.log('ErrorData', errorData)
  }

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <PrevArrow className={styles.prev} onClick={() => navigate(state?.pathToReturn || -1)} />
          <span>{t('bankAccount.steps.add.subtitle')}</span>
        </div>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
            <TextInput
              label={t('bankAccount.cbu_alias')}
              name="cbu_alias"
              placeholder={t('bankAccount.cbu_aliasPlaceholder')}
              withUseForm
              underlined
              required
            />
            <TextInput
              label={t('bankAccount.name')}
              name="name"
              placeholder={t('bankAccount.namePlaceholder')}
              withUseForm
              underlined
              required
            />
            <Button
              className={styles.button}
              styledType="filled"
              filledColor="primary"
              type="submit"
              loading={submitting}>
              {t('continue')}
            </Button>
          </form>
        </FormProvider>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(AddBankAccount)
