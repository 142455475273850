import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import Button from '../../../../../../components/Button/Button'
import InfoPanel from '../../../../../../components/InfoPanel/InfoPanel'
import {currentCurrency} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {customerApi, projectApi} from '../../../../../../services'
import {
  clearInvestmentStorage,
  getInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'

import styles from './SuccessfulPayment.module.scss'

const SuccessfulPayment = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {itemId} = useParams()
  const {quantity} = useCounterSlice()
  const {reset} = useStepperSlice()
  const {
    id: projectId,
    tokenomic: {
      token: {id: tokenId, price, currency, name},
    },
    setProject,
  } = useProjectSlice()
  const [signing, setSigning] = useState<boolean>(false)

  const getProject = useCallback(
    async (id: number) => {
      const project = await projectApi.getProject(id)
      setProject(id, project)
    },
    [itemId, projectId, setProject],
  )

  const signNow = useCallback(async () => {
    try {
      setSigning(true)
      const documentToSign = (await customerApi.getDocumentation()).pop()
      if (!documentToSign || !documentToSign?.signature?.url) return
      const a = document.createElement('a')
      a.href = documentToSign.signature.url
      a.target = '_blank'
      a.rel = 'noopener noreferrer'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      clearInvestmentStorage()
      reset()
      navigate(PATHS.PORTFOLIO)
    } catch (error) {
      console.error('Error signing file', error)
    } finally {
      setSigning(false)
    }
  }, [signing, setSigning])

  useEffect(() => {
    if (!projectId) !!itemId && getProject(+itemId)
  }, [itemId, projectId])

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`] || 1

  return (
    <>
      <InfoPanel
        type="warning"
        label={t('investment.successfulPayment.signWarning')}
        description={t('investment.successfulPayment.signWarningMessage')}
      />
      <div className={styles.header}>
        <div className={styles.title}>{t('investment.successfulPayment.title')}</div>
        <span className={styles.bought}>{t('investment.successfulPayment.bought')}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.projectData}>
          <span>{amountOfTokens}</span>
          {t('tokens', {
            count: amountOfTokens || 1,
          })}
          <span>{name}</span>
        </div>
        <div className={styles.line} />
        <span className={styles.price}>
          {formatNumber(amountOfTokens * +price)} {currency || currentCurrency}
        </span>
      </div>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={signNow}
        loading={signing}>
        {t('investment.successfulPayment.signNow')}
      </Button>
    </>
  )
}

export default withTranslation()(SuccessfulPayment)
