import {ReactNode} from 'react'

import {ReactComponent as ClientsIcon} from '../../../assets/icons/clients.svg'
import {ReactComponent as DashboardIcon} from '../../../assets/icons/dashboard.svg'
import {ReactComponent as ReservesIcon} from '../../../assets/icons/flag.svg'
import {ReactComponent as PaymentsIcon} from '../../../assets/icons/payments.svg'
import {ReactComponent as Refresh} from '../../../assets/icons/refresh.svg'
import {ReactComponent as SalesIcon} from '../../../assets/icons/sales.svg'
import {Section} from '../../../components/TabBar/TabBar'
import Config from '../../../utils/config'

import Clients from './Clients/Clients'
import Dashboard from './Dashboard/Dashboard'
import Payments from './Payments/Payments'
import Permutoken from './Permutoken/Permutoken'
import Reserves from './Reserves/Reserves'
import Sales from './Sales/Sales'

import styles from '../Panel.module.scss'

export enum PANEL_SECTION {
  dashboard = 'dashboard',
  clients = 'clients',
  reserves = 'reserves',
  sales = 'sales',
  payments = 'payments',
  permutoken = 'permutoken',
  // reports = 'reports',
  // setting = 'setting',
}

type PanelSection = {
  section: Section
  sectionComponent: ReactNode
  disabled?: boolean
}
export const panelSections: PanelSection[] = [
  {
    section: {
      keyLabel: PANEL_SECTION.dashboard,
      icon: <DashboardIcon className={styles['path-fill']} />,
    },
    sectionComponent: <Dashboard />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.clients,
      icon: <ClientsIcon className={styles['path-stroke']} />,
    },
    sectionComponent: <Clients />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.reserves,
      icon: <ReservesIcon className={styles['path-fill']} />,
    },
    sectionComponent: <Reserves />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.sales,
      icon: <SalesIcon className={styles['path-fill']} />,
    },
    sectionComponent: <Sales />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.payments,
      icon: <PaymentsIcon className={styles['path-stroke']} />,
    },
    sectionComponent: <Payments />,
  },
  {
    section: {
      keyLabel: PANEL_SECTION.permutoken,
      icon: <Refresh className={styles['path-fill']} />,
    },
    sectionComponent: <Permutoken />,
    disabled: !Config.isPermutokenEnabled,
  },
]

export const sections: Section[] = panelSections
  .filter(panelSection => !panelSection.disabled)
  .map(panelSection => panelSection.section)
