import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import Button from '../../../components/Button/Button'
import {useIdentityValidationSlice} from '../../../store/slices/identityValidation'
interface ICuilCuitCivilStatusForm {
  legal_id: string
  civil_status: string
}
const civilStates: string[] = ['SOLTERO', 'CASADO', 'VIUDO', 'DIVORCIADO', 'SEPARADO', 'OTRO']

import {useMemo, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'

import Dropdown, {IValue} from '../../../components/Dropdown/Dropdown'
import TextInput from '../../../components/TextInput/TextInput'
import {mantecaApi} from '../../../services'

import styles from './styles.module.scss'
const CuilCuitCivilStatus = ({t}: WithTranslation) => {
  const {nextStep, updateIdentityValidationData, identityValidationData} =
    useIdentityValidationSlice()
  const methods = useForm<ICuilCuitCivilStatusForm>({
    defaultValues: {
      legal_id: identityValidationData?.legal_id || '',
      civil_status: identityValidationData?.civil_status || '',
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)

  const civilStatesValues = useMemo<IValue[]>(
    () =>
      civilStates.map(
        civilState =>
          ({
            key: civilState,
            label: civilState.charAt(0).toUpperCase() + civilState.slice(1).toLowerCase(),
          } as IValue),
      ),
    [civilStates],
  )

  const onSubmit = async (data: ICuilCuitCivilStatusForm) => {
    try {
      setSubmitting(true)
      await mantecaApi.createUser(data.legal_id.replaceAll('-', ''), data.civil_status)
      updateIdentityValidationData({civil_status: data.civil_status, legal_id: data.legal_id})
      nextStep()
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<ICuilCuitCivilStatusForm> | FieldErrors<ICuilCuitCivilStatusForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  return (
    <>
      <div className={styles.subtitle}>
        <span>{t('identityValidation.steps.cuilCuitCivilStatus.subtitle')}</span>
        <span>
          <Trans i18nKey="identityValidation.help">
            ¿Necesitas ayuda?
            <a href="" target="_blank" rel="noopener noreferrer">
              Hacé click acá
            </a>
          </Trans>
        </span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <Controller
            control={methods.control}
            name="civil_status"
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}}) => (
              <Dropdown
                label={t('identityValidation.field.civil_status')}
                name="civil_status"
                classNameContainer={styles.dropdown}
                customClasses={{
                  head: styles.head,
                  arrow: styles.arrow,
                }}
                value={civilStatesValues.find(civilState => civilState.key === value) as IValue}
                setValue={onChange}
                options={civilStatesValues}
                underlined
              />
            )}
          />
          <TextInput
            label={t('identityValidation.field.cuil_cuit')}
            name="legal_id"
            placeholder="00-00000000-0"
            withUseForm
            underlined
            required
          />
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            // disabled={!methods.formState.isValid}
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </>
  )
}

export default withTranslation()(CuilCuitCivilStatus)
