import {ReactNode, useCallback, useEffect} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Arrow} from '../../../../../assets/icons/arrow-up.svg'
import {ReactComponent as Bank} from '../../../../../assets/icons/bank.svg'
import Button from '../../../../../components/Button/Button'
import {useUser} from '../../../../../contexts/UserContext'
import {BankAccount} from '../../../../../services/interfaces/IMantecaWithdraw'
import {useWithdrawalSlice} from '../../../../../store/slices/withdrawal'

import {WITHDRAWAL_PESOS_STEPS} from './steps'

import styles from './styles.module.scss'

export type WithdrawalMethod = 'pesos' | 'usdt'
export interface IWithdrawalMethod {
  className?: string
  type: WithdrawalMethod
  label: string
  description: string | null
  icon: ReactNode
  iconClassName?: string
}
const ChooseAccount = ({t}: WithTranslation) => {
  const {pesosBankAccounts, getPesosAccounts, fetchingAccounts} = useUser()
  const {goToStep, resetWithdrawal, updateWithdrawalData, lastStep} = useWithdrawalSlice()

  const onChooseAccount = useCallback(
    async (bankAccountChosen: BankAccount) => {
      const indexOfAccount = pesosBankAccounts.indexOf(bankAccountChosen)
      updateWithdrawalData({
        bankAccountChosen: {...pesosBankAccounts[indexOfAccount], account_index: indexOfAccount},
      })
      goToStep(WITHDRAWAL_PESOS_STEPS.CHECK_ACCOUNT)
    },
    [pesosBankAccounts],
  )

  useEffect(() => {
    getPesosAccounts()
  }, [])

  useEffect(() => {
    if (!fetchingAccounts && !pesosBankAccounts.length) lastStep()
  }, [fetchingAccounts, pesosBankAccounts])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={() => resetWithdrawal()} />
        <span>{t('withdrawal.title')}</span>
        <span>{t('withdrawal.subtitle')}</span>
      </div>
      <div className={styles.form}>
        {!!fetchingAccounts &&
          !pesosBankAccounts.length &&
          Array.from(Array(3).keys()).map(skeletonBox => (
            <div key={skeletonBox} className={styles.bankAccountContainer}>
              <div className={styles.bankAccount}>
                <div className={styles.box}>
                  <div
                    className={classNames(styles.circle, {[styles.loading]: !!fetchingAccounts})}>
                    <Skeleton width="100%" height="100%" circle />
                  </div>
                  <div className={styles.textContainer}>
                    <span className={styles.name}>
                      <Skeleton width={100} height={20} />
                    </span>
                    <span className={styles.description}>
                      <Skeleton width={150} height={15} />
                    </span>
                  </div>
                  <div className={styles.right}>
                    <Arrow className={styles.arrow} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!!pesosBankAccounts.length &&
          pesosBankAccounts.map((bankAccount: BankAccount) => (
            <div key={bankAccount.cbu} className={styles.bankAccountContainer}>
              <div className={styles.bankAccount} onClick={() => onChooseAccount(bankAccount)}>
                <div className={styles.box}>
                  <div className={styles.circle}>
                    <Bank />
                  </div>
                  <div className={styles.textContainer}>
                    <span className={styles.name}>{bankAccount.description}</span>
                    <span className={styles.description}>{bankAccount.cbu}</span>
                  </div>
                  <div className={styles.right}>
                    <Arrow className={styles.arrow} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={() => goToStep(WITHDRAWAL_PESOS_STEPS.ADD_ACCOUNT)}>
        {t('bankAccount.newAccount')}
      </Button>
    </>
  )
}

export default withTranslation()(ChooseAccount)
