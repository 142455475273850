export enum BRAND_KEY {
  criptokuantica = 'criptokuantica',
  bridge_positive = 'bridge_positive',
  impozo = 'impozo',
  domum = 'domum',
  muskuy = 'muskuy',
  ark = 'ark',
  deptoken = 'deptoken',
}
export const BRAND: keyof typeof BRAND_KEY = (process.env.REACT_APP_BRAND ||
  BRAND_KEY.criptokuantica) as keyof typeof BRAND_KEY
