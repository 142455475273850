export const PATHS = {
  HOME: '/',
  ITEM: '/item',
  PORTFOLIO: '/portfolio',
  RECENT_TRANSACTIONS: '/recent-transactions',
  PROFILE: '/profile',
  INVESTMENT: '/investment',
  PERSONAL_INFORMATION: '/personal-information',
  PERSONAL_INFORMATION_PERSON: '/personal-information/person',
  PERSONAL_INFORMATION_COMPANY: '/personal-information/company',
  ADD_FUNDS: '/add-funds',
  DEPOSIT: '/deposit',
  WITHDRAWAL: '/withdrawal',
  SWAP: '/swap',
  STRIPE: '/payment-method/stripe',
  BINANCE_PAY: '/payment-method/binance-pay',
  PANEL: '/panel',
  EXCHANGE: '/exchange',
  IDENTITY_VALIDATION: '/identity-validation',
  DOCUMENTATION: '/documentation',
  ADD_BANK_ACCOUNT: '/add-bank-account',
}
