import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as ArrowDown} from '../../assets/icons/arrow-down.svg'
import {ReactComponent as Download} from '../../assets/icons/download.svg'
import {ReactComponent as Empty} from '../../assets/icons/empty-state.svg'
import Card from '../../components/Card/Card'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {customerApi} from '../../services'
import {IDocumentation} from '../../services/interfaces/IReserve'
import {formatDate} from '../../utils/formatDate'
import {formatNumberToString} from '../../utils/formatNumber'
import {generateDownload} from '../../utils/generateDownload'

import styles from './Documentation.module.scss'

enum HEADER_TABLE {
  contract = 'contract',
  tokenAmount = 'tokenAmount',
  date = 'date',
  value = 'value',
  status = 'status',
  sign = 'sign',
  download = 'download',
}
const headers: string[] = Object.values(HEADER_TABLE)

const Documentation = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [contracts, setContracts] = useState<IDocumentation[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<string>('')

  const getContracts = useCallback(async () => {
    try {
      setLoading(true)
      const contracts = await customerApi.getDocumentation()
      setContracts(contracts)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [contracts, loading])

  const downloadContract = useCallback(
    async (envelopeId: string, name: string) => {
      try {
        setDownloading(envelopeId)
        const downloadUrl = await customerApi.downloadContract(envelopeId)
        generateDownload(downloadUrl, name, '.pdf')
      } catch (error) {
        console.log('Error on download file', error)
      } finally {
        setDownloading('false')
      }
    },
    [downloading, setDownloading],
  )

  useEffect(() => {
    getContracts()
  }, [])
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container}>
        <div className={styles.header}>
          <div className={styles.row}>
            <div className={styles.text}>
              <span onClick={() => navigate(-1)}>
                <ArrowDown />
                {t('documentation.title')}
              </span>
              <span>{t('documentation.subtitle')}</span>
            </div>
          </div>
        </div>
        <div className={styles.cardContainer}>
          <Card className={styles.card} withShadow>
            <table>
              <tbody>
                <tr>
                  {headers.map(header => (
                    <th key={header} className={styles[header]}>
                      {t(`documentation.header.${header}`)}
                    </th>
                  ))}
                </tr>
                {!loading &&
                  !!contracts.length &&
                  contracts.map((contract: IDocumentation) => (
                    <tr key={contract?.id}>
                      <td className={styles.contract}>
                        <span>{contract?.token.name}</span>
                      </td>
                      <td>
                        <span>
                          {formatNumberToString(contract?.token_amount)}{' '}
                          {t('tokens', {
                            count: +contract?.token_amount || 1,
                          })}
                        </span>
                      </td>
                      <td>
                        <span>{formatDate(contract.created_at)}</span>
                      </td>
                      <td>
                        <span>
                          {formatNumberToString(+contract?.token.price)}{' '}
                          {contract?.token.currency.toUpperCase()}
                        </span>
                      </td>
                      <td className={styles.status}>
                        <span className={styles[contract?.signature?.status.toLocaleLowerCase()]}>
                          {t(
                            `documentation.status.${contract?.signature?.status.toLocaleLowerCase()}`,
                          )}
                        </span>
                      </td>
                      <td className={styles.sign}>
                        {(!!contract?.signature?.url &&
                          (contract.signature.status?.toUpperCase() === 'STARTED' ||
                            contract.signature.status?.toUpperCase() === 'IN_PROGRESS') && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={contract?.signature?.url}>
                              {t('documentation.signNow')}
                            </a>
                          )) ||
                          '-'}
                      </td>
                      <td className={styles.download}>
                        <span
                          className={styles.downloadLinkButton}
                          onClick={() =>
                            downloadContract(contract?.signature.envelope_id, contract?.token.name)
                          }>
                          {downloading === contract?.signature?.envelope_id ? (
                            <LoadingSpinner className={styles.loading} />
                          ) : (
                            <Download className={styles.download} />
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                {loading &&
                  Array.from(Array(10).keys()).map(skeletonRow => (
                    <tr key={skeletonRow} className={styles.skeleton}>
                      {Array.from(Array(headers.length).keys()).map(headerRow => (
                        <td key={headerRow}>
                          <Skeleton width="60%" />
                        </td>
                      ))}
                    </tr>
                  ))}
                {!loading && !contracts.length && (
                  <tr className={styles.emptyState}>
                    <td colSpan={headers.length}>
                      <section>
                        <Empty />
                        <span>{t(`documentation.noContracts`)}</span>
                      </section>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Documentation)
