import {useEffect} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Arrow} from '../../../../../assets/icons/arrow-prev.svg'
import Stepper from '../../../../../components/Stepper/Stepper'
import {PATHS} from '../../../../../constants/paths'
import BannerContainer from '../../../../../containers/BannerContainer'
import BannerSection from '../../../../../containers/BannerSection'
import {useStepperSlice} from '../../../../../store/slices/stepper'
import {PANEL_SECTION} from '../../constants'

import styles from './Exchange.module.scss'

const Exchange = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {steps, activeStep, nextStep, lastStep, initStepper} = useStepperSlice()

  useEffect(() => {
    initStepper('exchange')
  }, [])
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <Arrow
            className={styles.prev}
            onClick={() =>
              (activeStep.number === 1 &&
                navigate(PATHS.PANEL, {state: {panelSection: PANEL_SECTION.permutoken}})) ||
              lastStep()
            }
          />
          <span>{t('exchange.title')}</span>
        </div>
        <Stepper
          customClasses={{
            container: styles.stepperContainer,
            stepsContainer: styles.stepsContainer,
          }}
          steps={steps}
          activeStep={activeStep}
          handleNextStep={nextStep}
        />
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Exchange)
