import {create, StateCreator} from 'zustand'

import {IStep} from '../../components/Stepper/types/Step'
import {investmentSteps} from '../../pages/investment/steps/constant'
import {exchangeSteps} from '../../pages/panel/sections/Permutoken/exchange/exchangeSteps'
import {getInvestmentStorage, saveInvestmentStorage} from '../localStorage/investment'

type StepperInstance = 'investment' | 'exchange'
export interface StepperSlice {
  steps: IStep[]
  activeStep: IStep
  activeItemId: number
  instance: StepperInstance
  initStepper: (instance: StepperInstance) => void
  nextStep: () => void
  lastStep: () => void
  setActiveItemId: (itemId: number) => void
  setActiveStep: (active: IStep) => void
  setSuccessfulStep: (step: IStep) => void
  reset: () => void
}

const getActiveStepByNumber = (number: number, instance: StepperInstance): IStep =>
  instance === 'investment'
    ? ({
        ...investmentSteps.filter(step => step.number === number)[0],
        isSuccessful: !!getInvestmentStorage()?.isPaymentSuccessful,
      } as IStep)
    : ({
        ...exchangeSteps.filter(step => step.number === number)[0],
        isSuccessful: !!getInvestmentStorage()?.isPaymentSuccessful,
      } as IStep)

const createStepperSlice: StateCreator<StepperSlice> = (set, get) => ({
  steps: investmentSteps,
  instance: 'investment',
  activeStep: getActiveStepByNumber(getInvestmentStorage()?.activeStepNumber || 1, 'investment'),
  activeItemId: 0,
  initStepper: (stepperInstance: StepperInstance) => {
    set(() => ({
      instance: stepperInstance,
    }))
    if (stepperInstance === 'investment')
      set(() => ({
        activeStep: investmentSteps[0],
        steps: investmentSteps,
      }))
    else if (stepperInstance === 'exchange')
      set(() => ({
        activeStep: exchangeSteps[0],
        steps: exchangeSteps,
      }))
  },
  setActiveItemId: (itemId: number) => set(() => ({activeItemId: itemId})),
  setActiveStep: (active: IStep) => {
    saveInvestmentStorage({activeStepNumber: active.number})
    set(() => ({activeStep: active}))
  },
  nextStep: () => {
    const {instance, activeStep, steps} = get()
    const nextStep =
      activeStep.number < steps.length
        ? getActiveStepByNumber(activeStep.number + 1, instance)
        : activeStep
    saveInvestmentStorage({activeStepNumber: nextStep.number})
    set({
      activeStep: nextStep,
    })
  },
  lastStep: () => {
    const {activeStep, instance} = get()
    const lastStep =
      activeStep.number > 1 ? getActiveStepByNumber(activeStep.number - 1, instance) : activeStep
    saveInvestmentStorage({activeStepNumber: lastStep.number})
    set({
      activeStep: lastStep,
    })
  },
  setSuccessfulStep: (successfulStep: IStep) => {
    saveInvestmentStorage({isPaymentSuccessful: true})
    set(state => ({
      steps: state.steps.map(step =>
        step.number === successfulStep.number ? ({...step, isSuccessful: true} as IStep) : step,
      ),
    }))
  },
  reset: () => set({steps: investmentSteps, activeStep: investmentSteps[0], activeItemId: 0}),
})

export const useStepperSlice = create<StepperSlice>()((...a) => ({
  ...createStepperSlice(...a),
}))
