import {useCallback, useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as PrevArrow} from '../../../../../assets/icons/arrow-prev.svg'
import {ReactComponent as Success} from '../../../../../assets/icons/success.svg'
import Button from '../../../../../components/Button/Button'
import DynamicInput from '../../../../../components/DynamicInput/DynamicInput'
import Modal from '../../../../../components/Modal/Modal'
import {CURRENCY} from '../../../../../constants/currencies'
import {PATHS} from '../../../../../constants/paths'
import {REG_EX} from '../../../../../constants/regExpression'
import {useUser} from '../../../../../contexts/UserContext'
import {mantecaApi} from '../../../../../services'
import {IChosenAccount, useWithdrawalSlice} from '../../../../../store/slices/withdrawal'
import {
  formatNumberToString,
  formatStringToNumber,
  getDecimalSeparator,
} from '../../../../../utils/formatNumber'

import {WITHDRAWAL_PESOS_STEPS} from './steps'

import styles from './styles.module.scss'

interface WithdrawalAmountForm {
  withdrawalAmount: string
}

const WithdrawalAmount = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {resetWithdrawal, withdrawalData, lastStep, goToStep} = useWithdrawalSlice()
  const {
    control,
    formState: {errors},
    watch,
    setValue,
  } = useForm<WithdrawalAmountForm>()
  const [withdrawalAmount] = watch(['withdrawalAmount'])
  const {user, pesosBalance, getPesosBalance, fetchingPesosBalance} = useUser()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [successModalVisible, showSuccessModal] = useState<boolean>(false)

  const withdrawal = useCallback(async () => {
    try {
      if (!withdrawalData?.bankAccountChosen) return
      setSubmitting(true)
      const withdrawStatus = await mantecaApi.withdraw(
        formatStringToNumber(withdrawalAmount),
        (withdrawalData.bankAccountChosen as IChosenAccount).account_index,
      )
      if (withdrawStatus !== 'CANCELLED' && withdrawStatus !== 'FAILED') showSuccessModal(true)
    } catch (error) {
    } finally {
      setSubmitting(false)
    }
  }, [withdrawalAmount])

  const onChangeAmount = useCallback(
    (amountValue: string) => {
      const thousandSeparator = (getDecimalSeparator() === '.' && ',') || '.'
      const amountValueFormatted = amountValue.replaceAll(thousandSeparator, '')
      if (!REG_EX.DECIMAL.test(amountValueFormatted)) return
      if (
        amountValue.endsWith(getDecimalSeparator()) ||
        (amountValue.includes(getDecimalSeparator()) && amountValue.endsWith('0'))
      ) {
        setValue('withdrawalAmount', amountValue)
        return
      }
      const originAmount = formatStringToNumber(amountValueFormatted)
      if (originAmount === 0) {
        setValue('withdrawalAmount', '0')
        return
      }
      setValue('withdrawalAmount', formatNumberToString(originAmount))
    },
    [withdrawalAmount],
  )

  useEffect(() => {
    if (!user) return
    getPesosBalance()
  }, [user])

  return (
    <>
      <div className={styles.header}>
        <PrevArrow className={styles.prev} onClick={lastStep} />
        <span>{t('withdrawal.label')}</span>
        <span>{t('withdrawal.howMuchDoYouWantToWithdraw')}</span>
      </div>
      <div className={styles.form}>
        <div className={styles.body}>
          <div className={styles.row}>
            <Controller
              control={control}
              name="withdrawalAmount"
              rules={{
                required: true,
              }}
              render={({field: {name, ...rest}}) => (
                <div
                  className={classNames(styles.inputContainer, {
                    [styles.error]: !!errors[name],
                  })}>
                  <DynamicInput
                    type="string"
                    {...rest}
                    minimalFontSize={30}
                    initialFontSize={60}
                    onChange={e => {
                      onChangeAmount(e.target.value)
                    }}
                  />
                  <span>{CURRENCY.ARS}</span>
                </div>
              )}
            />
          </div>
          <div className={styles.line} />
          <span className={styles.available}>
            <span>{t('withdrawal.available')}</span>
            {fetchingPesosBalance ? (
              <Skeleton width={70} height={15} />
            ) : (
              <>
                {pesosBalance} {CURRENCY.ARS}
              </>
            )}
          </span>
        </div>
        <div className={styles.rowsData}>
          <div className={styles.row}>
            <span>{t('bankAccount.currency')}</span>
            <span style={{fontWeight: 400}}>ARS</span>
          </div>
          <div className={styles.row}>
            <span>CBU</span>
            <span>{withdrawalData?.bankAccountChosen?.cbu || '012345678910111213141516'}</span>
          </div>
          <div className={styles.row}>
            <span>{t('bankAccount.bank')}</span>
            <span>{withdrawalData?.bankAccountChosen?.bankName || 'Santander Rio'}</span>
          </div>
          <div className={styles.row}>
            <span>{t('bankAccount.accountName')}</span>
            <span>{withdrawalData?.bankAccountChosen?.description || 'nombre'}</span>
          </div>
        </div>
        <Button
          className={styles.button}
          styledType="filled"
          filledColor="gray"
          onClick={() => goToStep(WITHDRAWAL_PESOS_STEPS.CHOOSE_ACCOUNT)}>
          {t('bankAccount.chooseAnotherAccount')}
        </Button>
        <Button
          style={{marginTop: 0}}
          className={styles.button}
          styledType="filled"
          filledColor="primary"
          disabled={+withdrawalAmount === 0 || +withdrawalAmount > +pesosBalance.replace('.', '')}
          loading={submitting}
          onClick={withdrawal}>
          {t('confirm')}
        </Button>
      </div>

      <Modal
        visible={successModalVisible}
        onClose={() => showSuccessModal(false)}
        closeButtonHidden
        outerClassName={styles.modal}
        containerClassName={styles.containerModal}
        icon={
          <div className={styles.success}>
            <Success />
            <span>{t('withdrawal.withdrawalInProgress')}</span>
          </div>
        }
        title={t('withdrawal.withdrawalInProgressMessage')}
        button={{
          label: t('close'),
          onClick: () => {
            navigate(PATHS.PORTFOLIO)
            resetWithdrawal()
          },
        }}
      />
    </>
  )
}

export default withTranslation()(WithdrawalAmount)
