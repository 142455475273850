import {create, StateCreator} from 'zustand'

import {BankAccount} from '../../services/interfaces/IMantecaWithdraw'

export interface IAddBankAccountData {
  cbu_alias?: string
  name?: string
  bankAccountChosen?: BankAccount | IChosenAccount | null
}

export interface IChosenAccount extends BankAccount {
  account_index: number
}
export interface AddBankAccountSlice {
  bankAccountData: IAddBankAccountData | undefined
  updateBankAccountData: (bankAccountData: IAddBankAccountData) => void
}

const createAddBankAccountSlice: StateCreator<AddBankAccountSlice> = set => ({
  bankAccountData: undefined,
  updateBankAccountData: (bankAccountData: IAddBankAccountData) => {
    set(state => ({bankAccountData: {...state.bankAccountData, ...bankAccountData}}))
  },
})

export const useAddBankAccountSlice = create<AddBankAccountSlice>()((...a) => ({
  ...createAddBankAccountSlice(...a),
}))
