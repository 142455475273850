import {Fragment, useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as Pesos} from '../../../../assets/icons/argentina.svg'
import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import {ReactComponent as Deposit} from '../../../../assets/icons/deposit.svg'
import {ReactComponent as Google} from '../../../../assets/icons/google.svg'
import {ReactComponent as Metamask} from '../../../../assets/icons/metamask.svg'
import {ReactComponent as Swap} from '../../../../assets/icons/swap.svg'
import {ReactComponent as Usdt} from '../../../../assets/icons/usdt.svg'
import {ReactComponent as Withdrawal} from '../../../../assets/icons/withdrawal.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import OnBoardingModal from '../../../../components/OnBoardingModal/OnBoardingModal'
import {CURRENCY} from '../../../../constants/currencies'
import {PATHS} from '../../../../constants/paths'
import {useUser} from '../../../../contexts/UserContext'
import {useWeb3} from '../../../../contexts/Web3Context'
import {magic} from '../../../../libs/magic'
import {onBoardingApi} from '../../../../services'
import {
  IOnBoardingError,
  IOnboardingResponse,
  ONBOARDING_SECTION,
} from '../../../../services/api/onBoarding'
import {COUNTRY} from '../../../../services/interfaces/IAddress'
import {VALIDATION_STATUS} from '../../../../services/interfaces/ICustomerInfo'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {useIdentityValidationSlice} from '../../../../store/slices/identityValidation'
import {ONBOARDING_TYPE, useOnBoarding} from '../../../../store/slices/onBoarding'
import {useWithdrawalSlice} from '../../../../store/slices/withdrawal'
import Config from '../../../../utils/config'
import InProgressValidationModal from '../../../identityValidation/InProgressValidation/InProgressValidationModal'

import styles from './MyWallet.module.scss'

interface IMyWallet extends WithTranslation {
  className?: string
}

const MyWallet = ({t}: IMyWallet) => {
  const navigate = useNavigate()
  const {
    user,
    balance,
    getBalance,
    pesosBalance,
    getPesosBalance,
    fetchingBalance,
    fetchingPesosBalance,
  } = useUser()
  const {
    customer_info: {address, manteca_user},
    refreshCustomer,
  } = useCustomerSlice()
  const {checkIdentityValidation, isUserValidated} = useIdentityValidationSlice()
  const {resetWithdrawal} = useWithdrawalSlice()
  const {web3} = useWeb3()
  const {activeStep, totalSteps, lastStep, nextStep, onBoardingCompleted, setOnBoardingCompleted} =
    useOnBoarding()
  const [validatingWallet, setValidating] = useState<boolean>(false)
  const [isMagic, setMagic] = useState<boolean>()
  const [isMetamask, setMetamask] = useState<boolean>()
  const [copiedVisible, showCopied] = useState<boolean>(false)
  const [inProgressModalVisible, showModalInProgressValidation] = useState<boolean>(false)

  const getWalletType = useCallback(async () => {
    try {
      setValidating(true)
      const walletProvider = await magic.wallet.getProvider()
      const userMetadata = await magic.user.getMetadata()
      const walletType = (userMetadata as any)?.walletType
      setMagic(walletType === 'magic' || !!(walletProvider as any)?.isMagic)
      setMetamask(walletType === 'metamask' || !(walletProvider as any)?.isMagic)
    } catch (error: any) {
      if (error?.code === -32603) return
      console.error(error)
    } finally {
      setValidating(false)
    }
  }, [isMagic, setMagic, isMetamask, setMetamask])

  const isOnBoardingCompleted = useCallback(async () => {
    try {
      const response: IOnboardingResponse | IOnBoardingError =
        await onBoardingApi.checkOnBoardingCompleted(ONBOARDING_SECTION.portfolio)
      setOnBoardingCompleted(!!(response as IOnboardingResponse)?.completed)
    } catch (error: any) {
      console.error(error)
    }
  }, [setOnBoardingCompleted, onBoardingCompleted])

  useEffect(() => {
    if (!user || !web3) return
    getBalance()
    getPesosBalance()
    getWalletType()
    isOnBoardingCompleted()
  }, [user, web3])

  useEffect(() => {
    if ((Config.isMantecaEnabled && isUserValidated) || !Config.isMantecaEnabled) return
    refreshCustomer()
    setTimeout(() => checkIdentityValidation(), 1000)
    const interval = setInterval(checkIdentityValidation, 10000)
    return () => clearInterval(interval)
  }, [checkIdentityValidation, refreshCustomer])

  const WButtons = () => (
    <div className={styles.buttons}>
      <OnBoardingModal
        className={styles.onBoardingDeposit}
        description={t('onBoardingModal.steps.second')}
        visible={activeStep.name === ONBOARDING_TYPE.DEPOSIT && !onBoardingCompleted}
        activeStep={activeStep.number}
        totalSteps={totalSteps}
        lastStep={lastStep}
        nextStep={nextStep}
      />
      <Button
        filledColor="white"
        className={classNames(
          styles.button,
          (activeStep.name === ONBOARDING_TYPE.DEPOSIT && !onBoardingCompleted && 'dim') || '',
          {[styles.validating]: validatingWallet},
        )}
        onClick={() => navigate(PATHS.DEPOSIT)}
        disabled={!isMagic || validatingWallet}>
        <Deposit className={styles.deposit} />
        <span>{t('profile.deposit')}</span>
      </Button>
      <Button
        filledColor="white"
        className={classNames(styles.button, {[styles.validating]: validatingWallet})}
        onClick={() => {
          resetWithdrawal()
          navigate(PATHS.WITHDRAWAL)
        }}
        disabled={!isMagic || validatingWallet}>
        <Withdrawal className={styles.withdrawal} />
        <span>{t('profile.withdrawal')}</span>
      </Button>
      <Button
        filledColor="primary"
        className={classNames(styles.button, {[styles.validating]: validatingWallet})}
        style={{display: (Config.isMantecaEnabled && 'flex') || 'none'}}
        onClick={() => {
          if (!isUserValidated) {
            if (
              !!manteca_user?.validation_status &&
              (manteca_user.validation_status.toUpperCase() === VALIDATION_STATUS.IN_PROGRESS ||
                manteca_user.validation_status.toUpperCase() === VALIDATION_STATUS.NOT_INITIATED)
            ) {
              showModalInProgressValidation(true)
              return
            }
            navigate(PATHS.IDENTITY_VALIDATION, {
              state: {pathOnValidationFinished: PATHS.PORTFOLIO},
            })
            return
          }
          navigate(PATHS.SWAP)
        }}
        disabled={!isMagic || validatingWallet}>
        <Swap className={styles.swap} />
        <span>{t('profile.swap')}</span>
      </Button>
    </div>
  )

  return (
    <>
      <Card
        className={classNames(
          styles.myWallet,
          (!fetchingBalance &&
            !!user &&
            activeStep.name === ONBOARDING_TYPE.WALLET &&
            !onBoardingCompleted &&
            'dim') ||
            '',
        )}
        withShadow>
        <div className={styles.header}>
          <div className={styles.wallet}>
            <span>
              {t('profile.myWallet')} {isMetamask ? <Metamask /> : <Google />}
            </span>
            <span
              className={classNames(styles.address, (copiedVisible && styles.copied) || '')}
              onClick={() => {
                if (!user) return
                showCopied(true)
                copyToClipboard(user)
                setTimeout(() => {
                  showCopied(false)
                }, 2500)
              }}>
              {(copiedVisible && t('copied')) || (
                <>
                  {(!!user &&
                    `${user?.substring(0, 6)}...${user?.substring(
                      user?.length - 6,
                      user?.length,
                    )}`) || <Skeleton width={150} />}
                  <Copy />
                </>
              )}
            </span>
          </div>
          <WButtons />
        </div>
        <div className={classNames(styles.rows, {[styles.loading]: fetchingBalance})}>
          <div className={styles.row}>
            <Usdt />
            <div className={styles.currency}>
              <span>{CURRENCY.USDT}</span>
              <span>{t('usdt')}</span>
            </div>
            <div className={styles.balance}>
              <span>
                {(!fetchingBalance && (
                  <>
                    {balance} {CURRENCY.USDT}
                  </>
                )) || <Skeleton width={100} height={18} />}
              </span>
              <span>
                {(!fetchingBalance && (
                  <>
                    {balance} {CURRENCY.USD}
                  </>
                )) || <Skeleton width={80} height={15} />}
              </span>
            </div>
          </div>
          {address?.country?.toUpperCase() === COUNTRY.ARGENTINA && Config.isMantecaEnabled && (
            <div className={classNames(styles.row, styles.pesos)}>
              <Pesos />
              <div className={styles.currency}>
                <span>{CURRENCY.ARS}</span>
                <span>{t('pesos')}</span>
              </div>
              <div className={styles.balance}>
                <span>
                  {(!fetchingPesosBalance && (
                    <>
                      {pesosBalance} {CURRENCY.ARS}
                    </>
                  )) || <Skeleton width={100} height={18} />}
                </span>
              </div>
            </div>
          )}
        </div>
        <OnBoardingModal
          className={styles.onBoardingWallet}
          description={t('onBoardingModal.steps.first')}
          visible={
            !fetchingBalance &&
            !!user &&
            activeStep.name === ONBOARDING_TYPE.WALLET &&
            !onBoardingCompleted
          }
          activeStep={activeStep.number}
          totalSteps={totalSteps}
          lastStep={lastStep}
          nextStep={nextStep}
        />
      </Card>

      <InProgressValidationModal
        visible={inProgressModalVisible}
        hide={() => showModalInProgressValidation(false)}
      />
    </>
  )
}
export default withTranslation()(MyWallet)
