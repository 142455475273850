import {ChangeEventHandler, ReactNode, useRef} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import classNames from 'classnames'

import styles from './Checkbox.module.scss'

interface ICheckboxProps {
  className?: string
  classNameContainer?: string
  label?: string | ReactNode | null
  name?: string
  filledColor?: 'primary' | 'secondary' | 'secondary-light' | 'light' | 'transparent' | 'white'
  withUseForm?: boolean
  required?: boolean
  onChange?: ChangeEventHandler<any> | undefined
  checked?: boolean
  disabled?: boolean
}
const Checkbox = ({
  className,
  classNameContainer,
  label,
  name,
  withUseForm = false,
  required = false,
  filledColor = 'primary',
  onChange,
  checked,
  disabled,
}: ICheckboxProps) => {
  const {
    control,
    formState: {errors},
  } = (withUseForm && useFormContext()) || {formState: {}}
  const inputRef = useRef(null)
  return (
    <div className={classNames(styles.container, classNameContainer)}>
      {withUseForm ? (
        <Controller
          control={control}
          name={name || ''}
          rules={{
            required: required,
          }}
          render={({field: {name, ...rest}}) => (
            <>
              <div
                className={classNames(styles.box, className, styles[`background-${filledColor}`], {
                  [styles.error]: !!errors && errors[name],
                })}>
                <input type="checkbox" {...rest} ref={inputRef} disabled={disabled} />
              </div>
              {/* <div className={classNames(styles.errorContainer, {[styles.error]: !!errors[name]})}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message?.toString()}
                </div>
              </div> */}
            </>
          )}
        />
      ) : (
        <>
          <div
            className={classNames(styles.box, className, styles[`background-${filledColor}`], {})}>
            <input
              type="checkbox"
              className={classNames(styles.input)}
              ref={inputRef}
              onChange={onChange}
              checked={!!checked}
              disabled={disabled}
            />
          </div>
          {/* <div className={classNames(styles.errorContainer, {[styles.error]: !!errors[name]})}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message?.toString()}
                </div>
              </div> */}
        </>
      )}
      {label && (
        <span
          className={classNames(styles.label, {
            [styles.error]: !!errors && errors[name || ''],
          })}>
          {label}
        </span>
      )}
    </div>
  )
}

export default Checkbox
