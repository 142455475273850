import {useCallback, useEffect, useRef, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {ReactComponent as Plus} from '../../../assets/icons/plus.svg'
import {ReactComponent as UploadedFile} from '../../../assets/icons/upload-file.svg'
import {ReactComponent as UploadedFileSuccess} from '../../../assets/icons/upload-file-success.svg'
import Button from '../../../components/Button/Button'
import UploadFile, {UploadFileHandle} from '../../../components/UploadFile/UploadFile'
import {PATHS} from '../../../constants/paths'
import {mantecaApi} from '../../../services'
import {FileData, useIdentityValidationSlice} from '../../../store/slices/identityValidation'
import InProgressValidationModal from '../InProgressValidation/InProgressValidationModal'

import styles from './styles.module.scss'
interface IFileForm {
  fundsAttachmentFile: FormData | undefined
}

const acceptedDocuments = [
  'salaryReceipt',
  'monotributistaPaymentVEPs',
  'registeredResponsible',
  'titlesOrPropertiesSale',
  'affidavitIG',
  'incomeAccountingCertification',
  'anyOtherSupports',
]

const SupportingDocuments = ({t}: WithTranslation) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const {identityValidationData, updateIdentityValidationData} = useIdentityValidationSlice()
  const methods = useForm<IFileForm>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [filesData, setFilesData] = useState<FileData[]>([])
  const [inProgressModalVisible, showModalInProgressValidation] = useState<boolean>(false)
  const uploadFileRef = useRef<UploadFileHandle>(null)

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      if (!filesData.length) {
        setErrorMessage(t('uploadFile.mustLoad'))
        return
      }
      let filesDataUploaded = filesData
      for (const fileData of filesData) {
        try {
          await mantecaApi.uploadFile(fileData.file, 'funds')
          filesDataUploaded = filesDataUploaded.map(data =>
            data.fileName === fileData.fileName ? ({...data, success: true} as FileData) : data,
          )
          setFilesData(filesDataUploaded)
        } catch (error) {
          filesDataUploaded = filesDataUploaded.map(data =>
            data.fileName === fileData.fileName ? ({...data, success: true} as FileData) : data,
          )
          setFilesData(filesDataUploaded)
          throw new Error('Error on file')
        }
      }
      updateIdentityValidationData({fundsAttachments: filesDataUploaded})
      await mantecaApi.finishValidation()
      showModalInProgressValidation(true)
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }

  const onSubmitError = (errorData: SubmitErrorHandler<IFileForm> | FieldErrors<IFileForm>) => {
    console.log('PIForm-ErrorData', errorData)
  }

  const onFileUploaded = useCallback(
    async (file: FormData | any): Promise<boolean> => {
      try {
        if (errorMessage) setErrorMessage(null)
        if (!file) {
          methods.setValue('fundsAttachmentFile', undefined)
          return false
        }
        const fileName = (((file as FormData)?.get('file') as File)?.name as string) || 'fileName'
        const isFileDuplicated = filesData.some(fileData => fileData.fileName === fileName)
        setFilesData(
          isFileDuplicated
            ? filesData.map(fileData =>
                fileData.fileName === fileName ? {file, fileName} : fileData,
              )
            : [...filesData, {file, fileName}],
        )
        methods.setValue('fundsAttachmentFile', file)
        return true
      } catch (error) {
        console.log('Error on uploading file', error)
        setErrorMessage(t('uploadFile.errorLoadingFile'))
        return false
      }
    },
    [errorMessage, setErrorMessage, methods.setValue, filesData, setFilesData],
  )

  useEffect(() => {
    if (
      !!identityValidationData?.fundsAttachments &&
      !!identityValidationData.fundsAttachments.length
    )
      setFilesData(identityValidationData.fundsAttachments)
  }, [identityValidationData])

  return (
    <>
      <div className={styles.subtitle}>
        <span>{t('identityValidation.steps.backID.subtitle')}</span>
        <span>
          <Trans i18nKey="identityValidation.help">
            ¿Necesitas ayuda?
            <a href="" target="_blank" rel="noopener noreferrer">
              Hacé click acá
            </a>
          </Trans>
        </span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          {!!filesData.length && (
            <div className={styles.files}>
              {filesData.map(fileData => (
                <div key={fileData.fileName}>
                  <span>
                    {fileData.success ? <UploadedFileSuccess /> : <UploadedFile />}
                    {fileData.fileName}
                  </span>
                  <span
                    onClick={() => {
                      setFilesData(filesData.filter(f => f.fileName !== fileData.fileName))
                      uploadFileRef.current?.clearFile()
                    }}>
                    {t('delete')}
                  </span>
                </div>
              ))}
            </div>
          )}
          <Controller
            control={methods.control}
            name="fundsAttachmentFile"
            // rules={{
            //   required: true,
            // }}
            render={({field: {name}}) => (
              <UploadFile
                // className={styles.uploader}
                name={name}
                label={t('identityValidation.field.supportingDocuments')}
                title={t('identityValidation.field.supportingDocuments_upload')}
                allowedFormats={['pdf', 'jpg', 'png']}
                onFileUploaded={onFileUploaded}
                externalErrorMessage={errorMessage}
                ref={uploadFileRef}
              />
            )}
          />
          <span
            className={styles.add}
            onClick={() => {
              methods.setValue('fundsAttachmentFile', undefined)
              setErrorMessage(null)
              uploadFileRef.current?.clearFile()
              uploadFileRef.current?.onClickFileInput()
            }}>
            <Plus />
          </span>
          <div className={styles.acceptedFiles}>
            <span>{t('identityValidation.acceptedDocuments.label')}:</span>
            <ol>
              {acceptedDocuments.map(document => (
                <li key={document}>{t(`identityValidation.acceptedDocuments.${document}`)}</li>
              ))}
            </ol>
          </div>

          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('finish')}
          </Button>
        </form>
      </FormProvider>
      <InProgressValidationModal
        visible={inProgressModalVisible}
        hide={() => navigate(state?.pathOnValidationFinished || PATHS.PORTFOLIO)}
      />
    </>
  )
}

export default withTranslation()(SupportingDocuments)
