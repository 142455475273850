import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
interface DynamicInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  initialFontSize: number
  minimalFontSize?: number
  maxPercentageWidth?: number
}
const DynamicInput: ForwardRefRenderFunction<HTMLInputElement, DynamicInputProps> = (
  {
    value = '',
    onChange,
    initialFontSize = 24,
    minimalFontSize = 24,
    maxPercentageWidth,
    ...rest
  }: DynamicInputProps,
  ref,
) => {
  const [fontSize, setFontSize] = useState(initialFontSize)
  const [maxWidth, setMaxWitdh] = useState(0)
  const inputRef = useRef<HTMLInputElement>(null)
  const spanRef = useRef<HTMLInputElement>(null)

  const handleInput = useCallback(() => {
    const input = inputRef.current
    const span = spanRef.current
    if (!input || !span) return
    span.textContent = value
    const inputWidth = input.clientWidth
    const textWidth = span.scrollWidth
    if (value.length <= 6) {
      setFontSize(initialFontSize)
      return
    }
    if (value.length > 6 && textWidth >= inputWidth)
      setFontSize(Math.max(fontSize * 0.85, minimalFontSize))
    else setFontSize(Math.min(fontSize * 1.1, initialFontSize))
  }, [
    value,
    fontSize,
    initialFontSize,
    minimalFontSize,
    maxWidth,
    inputRef?.current?.clientWidth,
    spanRef?.current?.scrollWidth,
  ])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e)
    },
    [onChange, handleInput],
  )
  useEffect(() => {
    if (!inputRef || !inputRef?.current) return
    setFontSize(initialFontSize)
    setMaxWitdh(inputRef.current.clientWidth)
  }, [initialFontSize])

  useEffect(() => {
    handleInput()
  }, [value])

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        maxWidth: `${maxPercentageWidth}%` || (maxWidth && `${maxWidth * 1.25}px`) || '50%',
      }}>
      <input
        {...rest}
        ref={inputRef || ref}
        value={value}
        onChange={handleChange}
        style={{
          fontSize: `${fontSize}px`,
          width: '100%',
          maxWidth: '100%',
        }}
      />
      <span
        ref={spanRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          whiteSpace: 'pre',
          fontSize: `${fontSize}px`,
        }}
      />
    </div>
  )
}

export default forwardRef(DynamicInput)
