import {useCallback, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Failure} from '../../../../assets/icons/error-alert.svg'
import {ReactComponent as Time} from '../../../../assets/icons/time.svg'
import Button from '../../../../components/Button/Button'
import Modal from '../../../../components/Modal/Modal'
import {PATHS} from '../../../../constants/paths'
import BannerContainer from '../../../../containers/BannerContainer'
import {useUser} from '../../../../contexts/UserContext'
import {useWeb3} from '../../../../contexts/Web3Context'
import {customerApi} from '../../../../services'
import {useWithdrawalSlice} from '../../../../store/slices/withdrawal'
import {formatStringToNumber} from '../../../../utils/formatNumber'

import styles from '../methods.module.scss'

export interface FundsWithdrawalForm {
  fundAmount: string
  destinationAddress: string
}

const Usdt = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {balance} = useUser()
  const {web3} = useWeb3()
  const [withdrawalInProgress, showWithdrawalInProgress] = useState<boolean>(false)
  const [withdrawalFailure, setWithdrawalFailure] = useState<boolean>(false)
  const [isWithdrawing, setWithdrawing] = useState<boolean>(false)
  const {withdrawalSteps, activeStepIndex, nextStep, resetWithdrawal} = useWithdrawalSlice()

  const methods = useForm<FundsWithdrawalForm>({
    defaultValues: {
      destinationAddress: '',
    },
  })

  const [amount] = methods.watch(['fundAmount'])

  const confirmTransaction = useCallback(
    async (formData: FundsWithdrawalForm) => {
      try {
        setWithdrawing(true)
        const withdrawResponse = await customerApi.withdraw(
          formData.destinationAddress,
          formatStringToNumber(formData.fundAmount),
        )
        if (withdrawResponse.status !== 200) throw new Error()

        await web3.eth
          .sendTransaction(withdrawResponse.data)
          .then(() => showWithdrawalInProgress(true))
          .catch((error: any) => {
            if (error?.code === -32603) return
            setWithdrawalFailure(true)
          })
          .finally(() => setWithdrawing(false))
      } catch (error) {
        setWithdrawalFailure(true)
        setWithdrawing(false)
      }
    },
    [
      web3,
      withdrawalInProgress,
      showWithdrawalInProgress,
      setWithdrawing,
      isWithdrawing,
      setWithdrawalFailure,
      withdrawalFailure,
    ],
  )

  const onSubmit = (formToSubmit: FundsWithdrawalForm) => {
    if (activeStepIndex === 0 && !(formatStringToNumber(amount) > 0)) return
    if (activeStepIndex === 1 && !formToSubmit.destinationAddress) return
    if (activeStepIndex === withdrawalSteps.length - 1) confirmTransaction(formToSubmit)
    else nextStep()
  }

  return (
    <>
      <BannerContainer className={styles.container} layout="column">
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>{withdrawalSteps[activeStepIndex]}</FormProvider>
          <Button
            type="submit"
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            loading={isWithdrawing}
            disabled={
              activeStepIndex === 0 && formatStringToNumber(amount) > formatStringToNumber(balance)
            }>
            {activeStepIndex < withdrawalSteps.length - 1 ? t('continue') : t('confirm')}
          </Button>
        </form>
      </BannerContainer>

      <Modal
        visible={withdrawalInProgress || withdrawalFailure}
        onClose={() => {
          showWithdrawalInProgress(false)
          setWithdrawalFailure(false)
        }}
        closeButtonHidden
        outerClassName={styles.modal}
        icon={
          <div className={(withdrawalInProgress && styles.pending) || styles.failure}>
            {(withdrawalInProgress && <Time />) || <Failure />}
            <span>{t((withdrawalInProgress && 'pending') || 'endpointFailed')}</span>
          </div>
        }
        title={t((withdrawalInProgress && 'withdrawal.processingWithdrawal') || 'tryLater')}
        subtitle={(withdrawalInProgress && t('withdrawal.transactionTimeWarning')) || undefined}
        button={{
          label: t('goToProfile'),
          onClick: () => {
            navigate(PATHS.PORTFOLIO)
            resetWithdrawal()
            setWithdrawalFailure(false)
          },
        }}
      />
    </>
  )
}

export default withTranslation()(Usdt)
