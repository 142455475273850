import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import QRCode from 'react-qr-code'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-next.svg'
import {ReactComponent as Time} from '../../../../../../assets/icons/time.svg'
import Button from '../../../../../../components/Button/Button'
import Modal from '../../../../../../components/Modal/Modal'
import {CURRENCY, CURRENCY_SYMBOL} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {saleApi} from '../../../../../../services'
import {
  clearInvestmentStorage,
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {usePaymentMethodSlice} from '../../../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'

import styles from './Belo.module.scss'

const Belo = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isReserving, setReserving] = useState<boolean>(false)
  const {quantity} = useCounterSlice()
  const {activePaymentMethod, availablePaymentMethods} = usePaymentMethodSlice()
  const [beloUrl, setBeloUrl] = useState<string>()
  const {
    contact_phone,
    tokenomic: {
      token: {id: tokenId, price, name: token_name},
    },
  } = useProjectSlice()
  const {lastStep, reset} = useStepperSlice()

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`] || 1

  const getBeloUrl = useCallback(async () => {
    const beloUrlResponse = await saleApi.belo(amountOfTokens, tokenId)
    setBeloUrl(beloUrlResponse)
  }, [amountOfTokens, tokenId, beloUrl, setBeloUrl])

  const confirmReserve = useCallback(async () => {
    try {
      setReserving(true)
      await saleApi.reserve(amountOfTokens, tokenId, CURRENCY.USDT, '')
      setSuccess(true)
    } catch (error) {
      console.log('Error on reserving', error)
    } finally {
      setReserving(false)
    }
  }, [amountOfTokens, tokenId, isReserving, setReserving, isSuccess, setSuccess])

  useEffect(() => {
    saveInvestmentStorage({activeSubStep: 'Belo'})
    getBeloUrl()
  }, [])

  return (
    <>
      <span className={styles.title}>{t('investment.belo.title')}</span>
      <div className={styles.price}>
        <span>
          <div className={styles.value}>{amountOfTokens}</div>
          <div className={styles.currency}> {'Token ' + token_name}</div>
        </span>
        <div className={styles.officialCurrency}>{`≈ ${formatNumber(amountOfTokens * +price)} ${
          CURRENCY_SYMBOL.USDT
        }`}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.qr}>
          <div className={styles.box}>
            {!!beloUrl ? (
              <QRCode level="L" value={beloUrl} />
            ) : (
              <Skeleton height="262px" width="262px" />
            )}
          </div>
          <span>
            {t('investment.belo.qrLabel')}{' '}
            <a href={beloUrl} target="_blank" rel="noopener noreferrer">
              {t('clickHere')}
            </a>
          </span>
        </div>
        <div className={styles.paymentMethod}>
          <div className={styles.label}>{t('investment.reserve.paymentMethod')}</div>
          <div className={styles.method}>
            {activePaymentMethod && (
              <span>
                <div className={classNames(styles.circle, activePaymentMethod.iconClassName)}>
                  {activePaymentMethod.icon}
                </div>
                {t(`investment.paymentMethod.${activePaymentMethod.key}`)}
              </span>
            )}
            {availablePaymentMethods.length > 1 && (
              <div
                className={styles.changeMethod}
                onClick={() => {
                  lastStep()
                  saveInvestmentStorage({
                    activeSubStep: undefined,
                  })
                }}>
                {t('investment.reserve.changeMethod')}
                <Arrow />
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isReserving}
        onClick={() => confirmReserve()}>
        {t('iHaveAlreadyPaid')}
      </Button>

      <Modal
        visible={isSuccess}
        onClose={() => setSuccess(false)}
        closeButtonHidden
        outerClassName={styles.outerModal}
        containerClassName={styles.containerModal}
        icon={
          <div className={styles.pending}>
            <Time />
            <span>{t('pending')}</span>
          </div>
        }
        title={t('investment.belo.processing')}
        subtitle={t('investment.belo.subtitle')}
        button={{
          label: t('continue'),
          onClick: () => {
            clearInvestmentStorage()
            reset()
            navigate(PATHS.PORTFOLIO)
          },
        }}>
        {!!contact_phone && (
          <span className={styles.description}>{`${t(
            'investment.belo.contact',
          )} ${contact_phone}`}</span>
        )}
      </Modal>
    </>
  )
}

export default withTranslation()(Belo)
