import {useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import Button from '../../../../../../components/Button/Button'
import {CURRENCY} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {permutokenApi} from '../../../../../../services'
import {EnchangeType} from '../../../../../../services/api/permutoken'
import {useExchangeSlice} from '../../../../../../store/slices/exchange'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {PANEL_SECTION} from '../../../constants'

import styles from './styles.module.scss'

interface IExchangeSummary extends WithTranslation {
  className?: string
}

const ExchangeSummary = ({className, t}: IExchangeSummary) => {
  const navigate = useNavigate()
  const {
    token_id,
    comments,
    exchange_type,
    total_paid,
    token_price,
    vesting,
    total_days,
    file_url,
  } = useExchangeSlice()
  const {setSuccessfulStep, activeStep} = useStepperSlice()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const submitExchange = useCallback(async () => {
    try {
      setSubmitting(true)
      await permutokenApi.createExchange({
        token: token_id as number,
        exchange_type: exchange_type as EnchangeType,
        comments: comments,
        total_paid: total_paid as number,
        file_url: file_url as string,
        vesting: vesting,
        total_days: total_days,
      })
      setSuccessfulStep(activeStep)
      navigate(PATHS.PANEL, {state: {panelSection: PANEL_SECTION.permutoken}})
    } catch (error) {
      console.log('Error on creating Exchange')
    } finally {
      setSubmitting(false)
    }
  }, [token_id, submitting])

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{t('exchange.summary')}</span>
      </div>
      <section>
        <div className={styles.line} />
        <div>
          <span>{t('exchange.fields.quotation')}</span>
          <span>
            {formatNumber(total_paid)} {CURRENCY.USD}
          </span>
        </div>
        <div>
          <span>{t('exchange.fields.willReceive')}</span>
          <span>
            {(total_paid as number) / (token_price || 1)}{' '}
            {t('tokens', {
              count: (total_paid as number) / (token_price || 1),
            })}
          </span>
        </div>
        <div>
          <span>{t('exchange.fields.tokenValue')}</span>
          <span>
            {formatNumber(+(token_price || 0))} {CURRENCY.USD}
          </span>
        </div>
        <div className={styles.line} />
        {vesting && (
          <div>
            <span>{t('exchange.fields.vesting')}</span>
            <span>
              {total_days} {t('day', {count: total_days})}
            </span>
          </div>
        )}
      </section>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        onClick={submitExchange}
        loading={submitting}>
        {t('continue')}
      </Button>
    </div>
  )
}

export default withTranslation()(ExchangeSummary)
