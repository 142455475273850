import {create, StateCreator} from 'zustand'

import {EnchangeType} from '../../services/api/permutoken'

export interface IExchangeState {
  token_id?: number
  token_price?: number
  exchange_type?: EnchangeType
  comments?: string
  total_paid?: number
  file_url?: string
  vesting?: boolean
  total_days?: number
}
const initialState: IExchangeState = {
  token_id: 0,
  token_price: 0,
  exchange_type: undefined,
  comments: '',
  total_paid: 0,
  file_url: '',
  vesting: false,
  total_days: 0,
}
type ExchangeActions = {
  setExchange: (exchange: IExchangeState) => void
  clearExchange: () => void
}

interface ExchangeSlice extends IExchangeState, ExchangeActions {}

const createExchangeSlice: StateCreator<ExchangeSlice> = set => ({
  ...initialState,

  setExchange: (exchange: IExchangeState) => {
    console.log('pre-set exchangeState', exchange)
    set(state => ({...state, ...exchange}))
  },
  clearExchange: () => {
    set(initialState)
  },

  clearProject: () => set(initialState),
})

export const useExchangeSlice = create<ExchangeSlice>()((...a) => ({
  ...createExchangeSlice(...a),
}))
