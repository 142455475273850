import React, {ReactNode} from 'react'
import classNames from 'classnames'

import styles from './BannerContainer.module.scss'

interface IBannerContainerProps {
  className?: string
  children: ReactNode
  layout?: 'row' | 'column'
}

const BannerContainer = ({className, children, layout = 'row'}: IBannerContainerProps) => (
  <div className={classNames(styles.container, styles[layout], className)}>{children}</div>
)

export default BannerContainer
