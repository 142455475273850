import {useCallback, useState} from 'react'
import {Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Camera} from '../../../assets/icons/camera.svg'
import Button from '../../../components/Button/Button'
import UploadFile from '../../../components/UploadFile/UploadFile'
import {mantecaApi} from '../../../services'
import {useIdentityValidationSlice} from '../../../store/slices/identityValidation'

import styles from './styles.module.scss'
interface IFileForm {
  frontIDAttachment: FormData | undefined
}

const FrontID = ({t}: WithTranslation) => {
  const {nextStep, identityValidationData, updateIdentityValidationData} =
    useIdentityValidationSlice()
  const methods = useForm<IFileForm>({
    defaultValues: {
      frontIDAttachment: identityValidationData?.frontIDAttachment,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const onSubmit = async (data: IFileForm) => {
    try {
      setSubmitting(true)
      await mantecaApi.uploadFile(data.frontIDAttachment as FormData, 'dni_front')
      updateIdentityValidationData({frontIDAttachment: data.frontIDAttachment})
      nextStep()
    } catch (error: any) {
      console.log('Error on put data', error)
      setErrorMessage(t('uploadFile.errorLoadingFile'))
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (errorData: SubmitErrorHandler<IFileForm> | FieldErrors<IFileForm>) => {
    console.log('PIForm-ErrorData', errorData)
  }

  const onFileUploaded = useCallback(
    async (file: FormData): Promise<boolean> => {
      try {
        if (errorMessage) setErrorMessage(null)
        if (!file) {
          methods.setValue('frontIDAttachment', undefined)
          return false
        }
        methods.setValue('frontIDAttachment', file)
        return true
      } catch (error) {
        console.log('Error on uploading file', error)
        setErrorMessage(t('uploadFile.errorLoadingFile'))
        return false
      }
    },
    [errorMessage, setErrorMessage, methods.setValue],
  )

  return (
    <>
      <div className={styles.subtitle}>
        <span>{t('identityValidation.steps.frontID.subtitle')}</span>
        <span>
          <Trans i18nKey="identityValidation.help">
            ¿Necesitas ayuda?
            <a href="" target="_blank" rel="noopener noreferrer">
              Hacé click acá
            </a>
          </Trans>
        </span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <Controller
            control={methods.control}
            name="frontIDAttachment"
            rules={{
              required: true,
            }}
            render={({field: {name}}) => (
              <UploadFile
                className={styles.uploader}
                name={name}
                label={t('identityValidation.field.frontID')}
                title={t('identityValidation.field.frontID_upload')}
                allowedFormats={['pdf', 'jpg', 'png']}
                onFileUploaded={onFileUploaded}
                externalErrorMessage={errorMessage}
                uploadFileIcon={
                  <span className={styles.circle}>
                    <Camera />
                  </span>
                }
              />
            )}
          />
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </>
  )
}

export default withTranslation()(FrontID)
