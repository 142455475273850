import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../assets/icons/arrow-down-purple-light.svg'
import emptyStateImg from '../../assets/images/empty-projects.png'
import {itemListSettings} from '../../constants/sliderSettings'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {projectApi} from '../../services'
import {IProject, TypologyType} from '../../services/interfaces/IProject'
import Card from '../Card/Card'
import ItemCard from '../ItemCard/ItemCard'
import ItemCardSkeleton from '../ItemCard/skeleton/ItemCardSkeleton'
import Slider from '../Slider/Slider'

import ButtonFilter from './components/ButtonFilter/ButtonFilter'

import styles from './ItemList.module.scss'

interface IItemList {
  design?: 'first' | 'second'
  straightCorners?: boolean
}
const ItemList = ({design = 'first', straightCorners}: IItemList) => {
  const {t} = useTranslation()
  const [isDescendingOrder, changeOrder] = useState<boolean>(false)
  const [projects, setProjects] = useState<IProject[]>()
  const [isFetching, setFetching] = useState<boolean>(false)
  const [filterSelected, setFilterSelected] = useState<'all' | TypologyType>()
  const getProjects = useCallback(async (filter?: 'all' | TypologyType) => {
    try {
      setFetching(true)
      setProjects([])
      const projectData = await projectApi.getOutstandingProjects(filter)
      setProjects(projectData)
    } catch (error) {
      console.error('Error on fetching Projects', setFetching)
    } finally {
      setFetching(false)
    }
  }, [])

  const renderProject = useCallback(
    (project: IProject, slideClass: string) => (
      <Card
        key={project?.id}
        className={classNames(slideClass, styles[design], {
          [styles.straightCorners]: straightCorners,
        })}
        withShadow>
        {isFetching ? (
          <ItemCardSkeleton layout="column" design={design} />
        ) : (
          !!projects?.length && (
            <ItemCard
              className={styles.itemCard}
              itemData={project}
              layout="column"
              showItemStatus
              showPercentageFloating
              showSoldTokens
              mainColor="secondary"
              design={design}
              straightCorners={straightCorners}
            />
          )
        )}
      </Card>
    ),
    [projects, filterSelected],
  )

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    if (filterSelected) getProjects(filterSelected)
  }, [filterSelected])

  if (!filterSelected && !!projects && projects.length < 4) return null

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('itemList.title')}</span>
          <div className={styles.filters}>
            <ButtonFilter selectFilter={setFilterSelected} />
            <div className={styles.separator} />
            <div
              className={classNames(styles.orderButton, {[styles.descending]: isDescendingOrder})}>
              {t('order')}
              <ArrowDown onClick={() => changeOrder(!isDescendingOrder)} />
            </div>
          </div>
        </div>
        <div className={styles.itemRow}>
          {!isFetching && !projects?.length ? (
            <div className={styles.emptyState}>
              <img src={emptyStateImg} />
              <div className={styles.header}>{t('itemList.emptyProjects')}</div>
              <div className={styles.footer}>{t('itemList.noTypology')}</div>
            </div>
          ) : (
            <Slider
              slides={!isFetching && !!projects?.length ? projects : Array.from(Array(4).keys())}
              className={styles.slider}
              customClasses={{
                swiperSlide: styles.swiperSlide,
                slide: styles.itemCard,
                bulletActive: styles.bulletActive,
                bullet: styles.bullet,
              }}
              renderSlide={renderProject}
              settings={itemListSettings}
              showBullet
            />
          )}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default ItemList
